<template>
  <MDBPopover v-if="popover" v-model="isPopoverActive" direction="top">
    <template #reference>
      <component
        :is="tag"
        v-bind="$attrs"
        ref="ratingItemRef"
        :class="[insertAfter || insertBefore ? 'px-2 d-flex' : null, classes]"
      >
        {{ insertBefore }}
        <MDBIcon
          :iconStyle="isActive ? 'fas' : 'far'"
          :icon="dynamicStyle && dynamicStyle.icon ? dynamicStyle.icon : icon"
          :size="size"
          :flag="flag"
          :class="[isActive && 'active', !color && 'text-primary', iconClass]"
          :style="{
            color:
              dynamicStyle && dynamicStyle.color ? dynamicStyle.color : color,
          }"
          @click="handleClick"
          @mouseenter="handleMouseEnter"
        />
        {{ insertAfter }}
      </component>
    </template>
    <template v-if="popover && popover !== true" #body>{{ popover }}</template>
    <template v-else #body><slot /></template>
  </MDBPopover>
  <MDBTooltip
    v-else
    v-model="isTooltipActive"
    :disabled="title ? undefined : true"
  >
    <template #reference>
      <component
        :is="tag"
        v-bind="$attrs"
        ref="ratingItemRef"
        :class="[insertAfter || insertBefore ? 'px-2 d-flex' : null, classes]"
      >
        {{ insertBefore }}
        <MDBIcon
          :iconStyle="isActive ? 'fas' : 'far'"
          :icon="dynamicStyle && dynamicStyle.icon ? dynamicStyle.icon : icon"
          :size="size"
          :flag="flag"
          :class="[isActive && 'active', !color && 'text-primary', iconClass]"
          :style="{
            color:
              dynamicStyle && dynamicStyle.color ? dynamicStyle.color : color,
          }"
          @click="handleClick"
          @mouseenter="handleMouseEnter"
        />
        {{ insertAfter }}
      </component>
    </template>
    <template #tip>
      {{ title }}
    </template>
  </MDBTooltip>
</template>

<script lang="ts">
export default {
  name: "MDBRatingItem",
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { computed, inject, ref, watch } from "vue";
import type { Ref } from "vue";
import MDBIcon from "../../free/content-styles/MDBIcon.vue";
import MDBTooltip from "../../free/components/MDBTooltip.vue";
import MDBPopover from "../../free/components/MDBPopover.vue";

interface DynamicStyle {
  icon: string;
  color: string | undefined;
}

const props = defineProps({
  tag: {
    type: String,
    default: "li",
  },
  iconClass: String,
  classes: String,
  icon: {
    type: String,
    default: "star",
  },
  flag: String,
  size: String,
  color: String,
  title: String,
  index: {
    type: Number,
    required: true,
  },
  popover: { type: [Boolean, String], default: false },
  insertBefore: String,
  insertAfter: String,
});

const ratingItemRef = ref(null);

const activeItem = inject<Ref<number> | false>("activeItem", false);
const hoveredItem = inject<Ref<number> | false>("hoveredItem", false);
const setActiveRating = inject<((rating: number, e: Event) => void) | false>(
  "setActiveRating",
  false
);
const setHoveredRating = inject<
  ((rating: number, e: Event | string) => void) | false
>("setHoveredRating", false);
const readonly = inject<boolean>("readonly", false);
const dynamic = inject<boolean>("dynamic", false);
const dynamicStyle = inject<DynamicStyle | false>("dynamicStyle", false);
const setDynamic = inject<
  ((icon: string, color: string | undefined) => void) | false
>("setDynamic", false);

const isActive = computed(() => {
  return (
    (hoveredItem &&
      !hoveredItem.value &&
      activeItem &&
      activeItem.value &&
      activeItem.value >= props.index) ||
    (hoveredItem && hoveredItem.value >= props.index)
  );
});

watch(
  [hoveredItem, activeItem],
  () => {
    if (dynamic && hoveredItem && activeItem && setDynamic) {
      const dynamicValue = hoveredItem.value
        ? hoveredItem.value
        : activeItem.value;
      dynamicValue === props.index && setDynamic(props.icon, props.color);
    }
  },
  { immediate: true }
);

const handleClick = (e: Event) => {
  if (props.popover) {
    isPopoverActive.value = !isPopoverActive.value;
  }
  !readonly && setActiveRating && setActiveRating(props.index, e);
};

const handleMouseEnter = (e: MouseEvent) => {
  !readonly && setHoveredRating && setHoveredRating(props.index, e);
};

const isTooltipActive = ref(false);
const isPopoverActive = ref(false);
</script>
