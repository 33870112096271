<template>
  <component
    :is="tag"
    :class="[
      'timepicker-head d-flex flex-row align-items-center justify-content-center',
      inline && 'timepicker-head-inline',
    ]"
    :style="{ paddingRight: `${inline ? 0 : hoursFormat ? 50 : 0}px` }"
  >
    <div
      :class="[
        'timepicker-head-content d-flex w-100 justify-content-evenly',
        inline && 'align-items-center',
      ]"
    >
      <div class="timepicker-current-wrapper">
        <span
          :class="[
            'position-relative h-100',
            inline && 'timepicker-inline-hour-icons',
          ]"
        >
          <i
            v-if="inline"
            class="fas fa-chevron-up position-absolute text-white timepicker-icon-up timepicker-icon-inline-hour"
            @mousedown="() => handleIncrementation('hours', 1)"
            @touchstart="() => handleIncrementation('hours', 1)"
            @mouseup="clearAsyncs"
            @touchend="clearAsyncs"
          ></i>
          <button
            type="button"
            :class="[
              'timepicker-current timepicker-hour',
              inline && 'timepicker-current-inline',
              unitsMode && unitsMode === 'hours' && 'active',
            ]"
            :style="{
              pointerEvents:
                unitsMode && unitsMode === 'hours' ? 'none' : 'auto',
            }"
            tabindex="0"
            v-mdb-ripple="ripple"
            @click="chageUnitsMode?.('hours')"
            @keydown.enter="chageUnitsMode?.('hours')"
            @keydown.space="chageUnitsMode?.('hours')"
          >
            {{ renderedHours }}
          </button>
          <i
            v-if="inline"
            :class="[
              'fas fa-chevron-down position-absolute text-white timepicker-icon-down timepicker-icon-inline-hour',
            ]"
            @mousedown="() => handleIncrementation('hours', -1)"
            @touchstart="() => handleIncrementation('hours', -1)"
            @mouseup="clearAsyncs"
            @touchend="clearAsyncs"
          ></i>
        </span>
        <button
          type="button"
          :class="['timepicker-dot', inline && 'timepicker-current-inline']"
          disabled
        >
          :
        </button>
        <span
          :class="[
            'position-relative h-100',
            inline && 'timepicker-inline-minutes-icons',
          ]"
        >
          <i
            v-if="inline"
            class="fas fa-chevron-up position-absolute text-white timepicker-icon-up timepicker-icon-inline-minute"
            @mousedown="() => handleIncrementation('minutes', increment)"
            @touchstart="() => handleIncrementation('minutes', increment)"
            @mouseup="clearAsyncs"
            @touchend="clearAsyncs"
          ></i>
          <button
            type="button"
            :class="[
              'timepicker-current timepicker-minute',
              inline && 'timepicker-current-inline',
              unitsMode === 'minutes' && 'active',
            ]"
            :style="{
              pointerEvents: unitsMode === 'minutes' ? 'none' : 'auto',
            }"
            tabindex="0"
            v-mdb-ripple="ripple"
            @click="chageUnitsMode?.('minutes')"
            @keydown.enter="chageUnitsMode?.('minutes')"
            @keydown.space="chageUnitsMode?.('minutes')"
          >
            {{ renderedMinutes }}
          </button>
          <i
            v-if="inline"
            class="fas fa-chevron-down position-absolute text-white timepicker-icon-down timepicker-icon-inline-minute"
            @mousedown="() => handleIncrementation('minutes', -increment)"
            @touchstart="() => handleIncrementation('minutes', -increment)"
            @mouseup="clearAsyncs"
            @touchend="clearAsyncs"
          ></i>
        </span>
      </div>
      <div
        v-if="hoursFormat === 12"
        :class="[
          'd-flex justify-content-center timepicker-mode-wrapper',
          !inline && 'flex-column',
        ]"
      >
        <button
          type="button"
          :class="[
            'timepicker-hour-mode timepicker-am',
            inline && 'me-2 ms-4',
            dayTime && dayTime === 'am' && 'active',
          ]"
          tabindex="0"
          v-mdb-ripple="ripple"
          @keydown.enter="changeDayTime?.('am')"
          @keydown.space="changeDayTime?.('am')"
          @click="changeDayTime?.('am')"
        >
          {{ amLabel }}
        </button>
        <button
          :class="[
            'timepicker-hour-mode timepicker-pm',
            dayTime && dayTime === 'pm' && 'active',
          ]"
          tabindex="0"
          :aria-label="pmLabel"
          v-mdb-ripple="ripple"
          @keydown.enter="changeDayTime?.('pm')"
          @keydown.space="changeDayTime?.('pm')"
          @click="changeDayTime?.('pm')"
        >
          {{ pmLabel }}
        </button>
        <button
          v-if="inline"
          type="button"
          class="timepicker-button timepicker-submit timepicker-submit-inline py-1 px-2 mb-0"
          tabindex="0"
          v-mdb-ripple="ripple"
          @click="handleOkClick"
          @keydown.enter="handleOkClick"
          @keydown.space="handleOkClick"
        >
          {{ okLabel }}
        </button>
      </div>
      <button
        v-else-if="hoursFormat === 24 && inline"
        class="timepicker-button timepicker-submit timepicker-submit-inline py-1 px-2 mb-0"
        tabindex="0"
        v-mdb-ripple="ripple"
        @click="handleOkClick"
        @keydown.enter="handleOkClick"
        @keydown.space="handleOkClick"
      >
        {{ okLabel }}
      </button>
    </div>
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBTimepickerHeader",
};
</script>

<script setup lang="ts">
import { computed, inject, onMounted, onUnmounted, ref } from "vue";
import type { ComputedRef, Ref } from "vue";
import { mdbRipple as vMdbRipple } from "../../../../index.free";
import {
  offMulti,
  onMulti,
} from "../../../../components/utils/MDBEventHandlers";

defineProps({
  tag: {
    type: String,
    default: "div",
  },
});

// ------------- TIMEPICKER PROPS INJECTS -------------
const hoursFormat = inject<number>("hoursFormat", 12);
const amLabel = inject<string>("amLabel", "AM");
const pmLabel = inject<string>("pmLabel", "PM");
const okLabel = inject<string>("okLabel", "ok");
const inline = inject<boolean>("inline", false);
const increment = inject<number>("increment", 1);
const ripple = inject<boolean>("ripple", false);

// ------------- STATE MANAGEMENT -------------
const hours = inject<ComputedRef<string | number>>(
  "hours",
  computed(() => 12)
);
const minutes = inject<ComputedRef<string | number>>(
  "minutes",
  computed(() => 0)
);
const dayTime = inject<Ref<string> | undefined>("dayTime", undefined);
const unitsMode = inject<Ref<string> | undefined>("unitsMode", undefined);

const changeDayTime = inject<((value: string) => void) | null>(
  "changeDayTime",
  null
);
const handleOkClick = inject<(() => void) | undefined>(
  "handleOkClick",
  undefined
);
const chageUnitsMode = inject<((value: string) => void) | null>(
  "chageUnitsMode",
  null
);
const incrementHoursValue = inject<
  | ((
      delta: number,
      currentValue?: number,
      recursion?: undefined | boolean
    ) => number)
  | null
>("incrementHoursValue", null);
const incrementMinutesValue = inject<
  | ((
      delta: number,
      currentValue?: number,
      recursion?: undefined | boolean
    ) => number)
  | null
>("incrementMinutesValue", null);

const renderedHours = computed(() => {
  const checkHours =
    typeof hours.value === "string" ? parseFloat(hours.value) : hours.value;
  if (isNaN(checkHours)) {
    return 12;
  }

  if (hoursFormat == 24 && hours.value === 24) {
    return "00";
  }

  return hours.value;
});

const renderedMinutes = computed(() =>
  isNaN(minutes?.value as number) ? "00" : minutes.value
);

const timeout = ref(0);
const interval = ref(0);

const setAndClearAsyncs = (method: () => void) => {
  clearAsyncs();
  timeout.value = setTimeout(() => {
    interval.value = setInterval(() => method(), 100);
  }, 500);
};

const clearAsyncs = () => {
  clearTimeout(timeout.value);
  clearInterval(interval.value);
};

const handleIncrementation = (type: string, value: number) => {
  const method = type === "hours" ? incrementHoursValue : incrementMinutesValue;
  method?.(value);

  setAndClearAsyncs(() => method?.(value));
};

onMounted(() => {
  onMulti(document.body, "mouseup touchend dragend", clearAsyncs);
});

onUnmounted(() => {
  offMulti(document.body, "mouseup touchend dragend", clearAsyncs);
});
</script>
