<template>
  <component :is="tag" class="timepicker-footer">
    <div class="w-100 d-flex justify-content-between">
      <button
        type="button"
        class="timepicker-button timepicker-clear"
        tabindex="0"
        v-mdb-ripple="ripple"
        @click="handleClear"
        @keydown.enter="handleClear"
        @keydown.space="handleClear"
      >
        {{ clearLabel }}
      </button>
      <button
        type="button"
        class="timepicker-button timepicker-cancel"
        tabindex="0"
        v-mdb-ripple="ripple"
        @click="handleCancelClick"
        @keydown.enter="handleCancelClick"
        @keydown.space="handleCancelClick"
      >
        {{ cancelLabel }}
      </button>
      <button
        type="button"
        class="timepicker-button timepicker-submit"
        tabindex="0"
        v-mdb-ripple="ripple"
        @click="handleOkClick"
        @keydown.enter="handleOkClick"
        @keydown.space="handleOkClick"
      >
        {{ okLabel }}
      </button>
    </div>
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBTimepickerFooter",
};
</script>

<script setup lang="ts">
import { inject } from "vue";
import { mdbRipple as vMdbRipple } from "../../../../index.free";

defineProps({
  tag: {
    type: String,
    default: "div",
  },
});

const handleClear = () => {
  handleClearClick();
  emitClearEvent?.();
};

// ------------- TIMEPICKER PROPS INJECTS -------------
const okLabel = inject<string | null>("okLabel", null);
const clearLabel = inject<string | null>("clearLabel", null);
const cancelLabel = inject<string | null>("cancelLabel", null);

const defaultMethod = () => {};

const handleOkClick = inject<() => void>("handleOkClick", defaultMethod);
const handleClearClick = inject<() => void>("handleClearClick", defaultMethod);
const handleCancelClick = inject<() => void>(
  "handleCancelClick",
  defaultMethod
);
const ripple = inject<boolean>("ripple", false);

const emitClearEvent = inject<() => void>("emitClearEvent", null);
</script>
