import { Chart } from "chart.js";

const chartsDefaultOptions = {
  line: {
    elements: {
      line: {
        backgroundColor: "rgba(66, 133, 244, 0.0)",
        borderColor: "rgb(66, 133, 244)",
        borderWidth: 2,
        tension: 0.0,
      },
      point: {
        borderColor: "rgb(66, 133, 244)",
        backgroundColor: "rgb(66, 133, 244)",
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        intersect: false,
        mode: "index",
      },
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: "rgba(0,0,0, 0.5)",
        },
      },
      y: {
        stacked: false,
        grid: {
          borderDash: [2],
          drawBorder: false,
          tickBorderDash: [2],
          tickBorderDashOffset: [2],
        },
        ticks: {
          color: "rgba(0,0,0, 0.5)",
        },
      },
    },
  },
  bar: {
    elements: {
      line: {
        backgroundColor: "rgb(66, 133, 244)",
      },
      bar: {
        backgroundColor: "rgb(66, 133, 244)",
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        intersect: false,
        mode: "index",
      },
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: "rgba(0,0,0, 0.5)",
        },
      },
      y: {
        stacked: false,
        grid: {
          borderDash: [2],
          drawBorder: false,
          color: function (context: any) {
            if (context.tick && context.tick.value === 0) {
              return "rgba(0,0,0, 0)";
            }
            return Chart.defaults.borderColor;
          },
          tickBorderDash: [2],
          tickBorderDashOffset: [2],
        },
        ticks: {
          color: "rgba(0,0,0, 0.5)",
        },
      },
    },
  },
  pie: {
    elements: {
      arc: { backgroundColor: "rgb(66, 133, 244)" },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
  },
  doughnut: {
    elements: {
      arc: { backgroundColor: "rgb(66, 133, 244)" },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
  },
  polarArea: {
    elements: {
      arc: { backgroundColor: "rgba(66, 133, 244, 0.5)" },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
  },
  radar: {
    elements: {
      line: {
        backgroundColor: "rgba(66, 133, 244, 0.5)",
        borderColor: "rgb(66, 133, 244)",
        borderWidth: 2,
      },
      point: {
        borderColor: "rgb(66, 133, 244)",
        backgroundColor: "rgb(66, 133, 244)",
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
  },
  scatter: {
    elements: {
      line: {
        backgroundColor: "rgba(66, 133, 244, 0.5)",
        borderColor: "rgb(66, 133, 244)",
        borderWidth: 2,
        tension: 0.0,
      },
      point: {
        borderColor: "rgb(66, 133, 244)",
        backgroundColor: "rgba(66, 133, 244, 0.5)",
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        intersect: false,
        mode: "index",
      },
      legend: {
        display: true,
      },
    },
    datasets: {
      borderColor: "red",
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: "rgba(0,0,0, 0.5)",
        },
      },
      y: {
        stacked: false,
        grid: {
          borderDash: [2],
          drawBorder: false,
          tickBorderDash: [2],
          tickBorderDashOffset: [2],
        },
        ticks: {
          color: "rgba(0,0,0, 0.5)",
        },
      },
    },
  },
  bubble: {
    elements: {
      point: {
        borderColor: "rgb(66, 133, 244)",
        backgroundColor: "rgba(66, 133, 244, 0.5)",
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: "rgba(0,0,0, 0.5)",
        },
      },
      y: {
        grid: {
          borderDash: [2],
          drawBorder: false,
          tickBorderDash: [2],
          tickBorderDashOffset: [2],
        },
        ticks: {
          color: "rgba(0,0,0, 0.5)",
        },
      },
    },
  },
};

export default chartsDefaultOptions;
