<template>
  <div class="datepicker-header">
    <div class="datepicker-title">
      <span class="datepicker-title-text">{{ title }}</span>
    </div>
    <div class="datepicker-date">
      <span class="datepicker-date-text">
        <slot
          v-if="customHeader"
          name="header"
          :headerWeekday="headerWeekday"
          :headerMonth="headerMonth"
          :headerMonthday="headerMonthday"
          :selected="headerDate"
        ></slot>
        <span v-else>
          {{ headerWeekday }}, {{ headerMonth }} {{ headerMonthday }}</span
        >
      </span>
    </div>
    <div v-if="dateTimepicker" class="buttons-container">
      <button
        type="button"
        class="datepicker-button-toggle"
        data-mdb-toggle="datepicker"
      >
        <i class="far fa-calendar datepicker-toggle-icon"></i>
      </button>
      <button
        type="button"
        class="timepicker-button-toggle"
        data-mdb-toggle="timepicker"
        @click="changePicker && changePicker('timepicker')"
      >
        <i class="far fa-clock fa-sm timepicker-icon"></i>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "MDBDatepickerHeader",
};
</script>

<script setup lang="ts">
import { inject, computed } from "vue";
import type { Ref } from "vue";
import dayjs from "dayjs";

interface DateTimepicker {
  inputRef: HTMLElement;
}

const headerDate = inject<Ref<dayjs.Dayjs>>("headerDate");
const weekdaysShort = inject<string[]>("weekdaysShort");
const monthsShort = inject<string[]>("monthsShort");
const customHeader = inject<boolean>("customHeader");
const title = inject("title");

const headerWeekday = computed(
  () =>
    weekdaysShort !== undefined &&
    headerDate !== undefined &&
    weekdaysShort[headerDate.value.day()]
);
const headerMonth = computed(
  () =>
    monthsShort !== undefined &&
    headerDate !== undefined &&
    monthsShort[headerDate.value.month()]
);
const headerMonthday = computed(() => headerDate?.value.date());

const dateTimepicker = inject<DateTimepicker | null>("dateTimepicker", null);
const changePicker = inject<((picker: string) => void) | null>(
  "changePicker",
  null
);
</script>
