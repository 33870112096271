<template>
  <component :is="tag" ref="scrollbarRef" style="position: relative">
    <slot />
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBPerfectScrollbar",
};
</script>

<script setup lang="ts">
import { ref, watch, onMounted, onUnmounted, PropType, nextTick } from "vue";
import { on, off } from "../../utils/MDBEventHandlers";

import PerfectScrollbar from "perfect-scrollbar";

const originalEvents = [
  "scroll",
  "ps-scroll-y",
  "ps-scroll-x",
  "ps-scroll-up",
  "ps-scroll-down",
  "ps-scroll-left",
  "ps-scroll-right",
  "ps-y-reach-start",
  "ps-y-reach-end",
  "ps-x-reach-start",
  "ps-x-reach-end",
];

const props = defineProps({
  tag: {
    type: String,
    default: "div",
  },
  options: {
    type: Object as PropType<PerfectScrollbar.Options>,
    default: () => ({}),
  },
});

const emit = defineEmits([
  "scroll",
  "ps-scroll-y",
  "ps-scroll-x",
  "ps-scroll-up",
  "ps-scroll-down",
  "ps-scroll-left",
  "ps-scroll-right",
  "ps-y-reach-start",
  "ps-y-reach-end",
  "ps-x-reach-start",
  "ps-x-reach-end",
]);

const scrollbarRef = ref<HTMLElement | null>(null);

const psInstance = ref<PerfectScrollbar | null>(null);

const initializePS = () => {
  if (scrollbarRef.value) {
    psInstance.value = new PerfectScrollbar(scrollbarRef.value, props.options);
    addEventListeners();

    nextTick(() => {
      scrollbarRef.value?.dispatchEvent(new Event("scroll"));
    });
  }
};

const disposePS = () => {
  if (psInstance.value) {
    psInstance.value.destroy();
    psInstance.value = null;
    removeEventListeners();
  }
};

const forceEventEmit = (eventName: any) => {
  emit(eventName);
};

const addEventListeners = () => {
  originalEvents.forEach((eventName) => {
    if (scrollbarRef.value) {
      on(scrollbarRef.value, eventName, () => forceEventEmit(eventName));
    }
  });
};

const removeEventListeners = () => {
  originalEvents.forEach((eventName) => {
    if (scrollbarRef.value) {
      off(scrollbarRef.value, eventName, () => forceEventEmit(eventName));
    }
  });
};

watch(
  () => props.options,
  () => {
    disposePS();
    initializePS();
  },
  { deep: true }
);

onMounted(() => {
  if (scrollbarRef.value) {
    initializePS();
  }
});

onUnmounted(() => {
  if (psInstance.value) {
    disposePS();
  }
});

defineExpose({
  psInstance,
});
</script>
