<template>
  <div class="datepicker-date-controls">
    <button
      class="datepicker-view-change-button"
      :aria-label="switchToDayViewLabel"
      @click.stop="changeView && changeView('day')"
    >
      {{ activeDate?.year() }}
    </button>
    <div class="datepicker-arrow-controls">
      <button
        class="datepicker-previous-button"
        :aria-label="prevYearLabel"
        @click="
          changeActiveYear &&
            activeDate &&
            changeActiveYear(activeDate.year() - 1)
        "
      ></button>
      <button
        class="datepicker-next-button"
        :aria-label="nextYearLabel"
        @click="
          changeActiveYear &&
            activeDate &&
            changeActiveYear(activeDate.year() + 1)
        "
      ></button>
    </div>
  </div>

  <div
    ref="datepickerViewRef"
    class="datepicker-view"
    tabindex="0"
    @keydown.up.prevent="handleDecrementMonth?.(4)"
    @keydown.down.prevent="handleIncrementMonth?.(4)"
    @keydown.left.prevent="handleDecrementMonth?.(1)"
    @keydown.right.prevent="handleIncrementMonth?.(1)"
    @keydown.enter="handleEnter"
    @keydown="handleKeydown"
    @focus="isDatepickerViewFocused = true"
    @blur="isDatepickerViewFocused = false"
  >
    <table class="datepicker-table">
      <tbody class="datepicker-table-body">
        <tr v-for="(monthRow, key) in months" :key="key">
          <td
            v-for="(month, keyInMonth) in monthRow"
            :key="keyInMonth"
            class="datepicker-cell datepicker-large-cell datepicker-month-cell"
            :class="[
              month.current && 'current',
              month.focused && isDatepickerViewFocused && 'focused',
              !month.selectable && 'disabled',
              month.selected && 'selected',
            ]"
            :aria-label="month.ariaLabel"
            :aria-disabled="!month.selectable || undefined"
            @click.stop="
              () => {
                if (!month.disabled) {
                  changeActiveMonth &&
                    (month.key || month.key === 0) &&
                    changeActiveMonth(month.key);
                  changeView && changeView('day');
                }
              }
            "
          >
            <div class="datepicker-cell-content datepicker-large-cell-content">
              {{ month.label }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
export default {
  name: "MDBDatepickerMonthView",
};
</script>

<script setup lang="ts">
import { inject, computed, ref, onMounted } from "vue";
import type { Ref } from "vue";
import dayjs from "dayjs";

interface Day {
  current?: boolean;
  dayJS?: dayjs.Dayjs;
  disabled?: boolean;
  focused?: boolean;
  label?: string;
  key?: number;
  selectable?: boolean;
  selected?: boolean;
  ariaLabel?: string;
}

const activeDate = inject<Ref<dayjs.Dayjs>>("activeDate");
const selectedDate = inject<Ref<dayjs.Dayjs>>("selectedDate");
const focusedDate = inject<Ref<dayjs.Dayjs>>("focusedDate");
const setFocusedDate = inject<(date: dayjs.Dayjs) => void>("setFocusedDate");
const monthsShort = inject<string[]>("monthsShort");
const changeActiveYear = inject<(year: number) => void>("changeActiveYear");
const changeActiveMonth = inject<(month: number) => void>("changeActiveMonth");
const changeView = inject<(newView: string) => void>("changeView");
const today = inject<Ref<dayjs.Dayjs> | dayjs.Dayjs>("today");
const nextYearLabel = inject<string>("nextYearLabel");
const prevYearLabel = inject<string>("prevYearLabel");
const switchToDayViewLabel = inject<string>("switchToDayViewLabel");

const minDate = inject<Ref<string | dayjs.Dayjs>>("minDate");
const maxDate = inject<Ref<string | dayjs.Dayjs>>("maxDate");
const disablePast = inject<boolean>("disablePast");
const disableFuture = inject<boolean>("disableFuture");

const months = computed(() => {
  const list: Day[][] = [];

  for (let i = 0; i < 3; i++) {
    list.push([]);
    const rowStartingDate =
      activeDate !== undefined &&
      activeDate.value.startOf("year").add(i * 4, "month");

    for (let j = 0; j < 4; j++) {
      const colStartingDate =
        rowStartingDate && rowStartingDate.add(j, "month");

      if (monthsShort && colStartingDate && focusedDate && selectedDate) {
        list[i].push({
          ariaLabel: `${
            monthsShort[colStartingDate.month()]
          }, ${colStartingDate.year()}`,
          current:
            colStartingDate.month() === (today as dayjs.Dayjs).month() &&
            colStartingDate.year() === (today as dayjs.Dayjs).year(),
          focused: colStartingDate.month() === focusedDate.value.month(),
          key: colStartingDate.month(),
          label: monthsShort[colStartingDate.month()],
          selectable: isMonthSelectable(colStartingDate),
          selected:
            colStartingDate.isSame(selectedDate.value, "month") &&
            colStartingDate.isSame(selectedDate.value, "year"),
        });

        if (colStartingDate.month() === focusedDate.value.month()) {
          const newFocusedDateMonth = colStartingDate;
          const newFocusedDate = newFocusedDateMonth
            .set("date", focusedDate.value.date())
            .set("year", activeDate.value.year());
          setFocusedDate?.(newFocusedDate);
        }
      }
    }
  }

  return list;
});

const isMonthSelectable = (date: dayjs.Dayjs) => {
  if (disablePast) {
    return date
      .add(1, "month")
      .isSameOrAfter((today as Ref<dayjs.Dayjs>).value);
  } else if (disableFuture) {
    return date.isSameOrBefore((today as Ref<dayjs.Dayjs>).value);
  } else if (minDate?.value && maxDate?.value) {
    return (
      date.isSameOrAfter(minDate.value, "month") &&
      date.isSameOrBefore(maxDate.value, "month") &&
      isRangeYearSelectable(date.year())
    );
  } else if (minDate?.value) {
    return (
      date.isSameOrAfter(minDate.value, "month") &&
      isMinYearSelectable(date.year())
    );
  } else if (maxDate?.value) {
    return (
      date.isSameOrBefore(maxDate.value, "month") &&
      isMaxYearSelectable(date.year())
    );
  }
  return true;
};

const isRangeYearSelectable = (year: number) => {
  if (
    minDate !== undefined &&
    typeof minDate.value !== "string" &&
    maxDate !== undefined &&
    typeof maxDate.value !== "string"
  ) {
    if (year >= minDate.value.year() && year <= maxDate.value.year()) {
      return true;
    }
  }

  return false;
};
const isMinYearSelectable = (year: number) => {
  if (minDate !== undefined && typeof minDate.value !== "string") {
    if (year >= minDate.value.year()) {
      return true;
    }
  }

  return false;
};
const isMaxYearSelectable = (year: number) => {
  if (maxDate !== undefined && typeof maxDate.value !== "string") {
    if (year <= maxDate.value.year()) {
      return true;
    }
  }

  return false;
};

// keyboard accessibility -------------------------------------
const handleDecrementMonth = inject<(key: number) => void>(
  "handleDecrementMonth"
);
const handleIncrementMonth = inject<(key: number) => void>(
  "handleIncrementMonth"
);
const handleMonthHome = inject<() => void>("handleMonthHome");
const handleMonthEnd = inject<() => void>("handleMonthEnd");
const handleMonthPageUp = inject<() => void>("handleMonthPageUp");
const handleMonthPageDown = inject<() => void>("handleMonthPageDown");
const datepickerViewRef = ref<HTMLElement | null>(null);
const isDatepickerViewFocused = ref(false);

const handleEnter = () => {
  if (focusedDate && !isMonthSelectable(focusedDate.value)) return;
  focusedDate && changeActiveMonth?.(focusedDate.value.month());
  changeView?.("day");
};
const handleKeydown = (event: KeyboardEvent) => {
  switch (event.key) {
    case "Home":
      handleMonthHome?.();
      break;
    case "End":
      handleMonthEnd?.();
      break;
    case "PageUp":
      handleMonthPageUp?.();
      break;
    case "PageDown":
      handleMonthPageDown?.();
      break;
    default:
      break;
  }
};
onMounted(() => {
  setTimeout(() => {
    datepickerViewRef.value?.focus();
  }, 100);
});
</script>
