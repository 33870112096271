<template>
  <component
    :is="tag"
    :id="uid"
    ref="lightboxItemRef"
    :data-lightbox-src="fullScreenSrc"
    :data-lightbox-caption="caption"
    :data-lightbox-disabled="disabled ? true : null"
    :class="{
      ['lightbox-disabled']: disabled,
    }"
    @click="handleClick"
  >
    <slot />
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBLightboxItem",
};
</script>

<script setup lang="ts">
import { ref, inject } from "vue";
import type { Ref } from "vue";
import { getUID } from "../../../../../src/components/utils/getUID";

interface Image {
  active: boolean;
  alt: string;
  caption: string | undefined;
  disabled: string | undefined;
  id: number;
  left: number;
  opacity: number;
  ref: HTMLImageElement;
  refId: string;
  scale: number;
  src: string;
  thumbnail: string | undefined;
}

const props = defineProps({
  tag: {
    type: String,
    default: "img",
  },
  id: String,
  fullScreenSrc: String,
  caption: String,
  disabled: Boolean,
});

const emit = defineEmits(["update:modelValue", "item-click", "item-hover"]);

const lightboxItemRef = ref(null);
const uid = props.id || getUID("MDBLightboxItem-");

const toggleLightbox = inject<((itemId?: number) => void) | false>(
  "toggleLightbox",
  false
);
const initialImagesArray = inject<Ref<Image[]> | []>("initialImagesArray", []);

const findImg = () => {
  return (
    !Array.isArray(initialImagesArray) &&
    initialImagesArray.value &&
    initialImagesArray.value.find((img: Image) => img.refId === uid)
  );
};

const handleClick = () => {
  const image = findImg();
  if (toggleLightbox && image) {
    toggleLightbox(image.id);
  }
};
</script>
