<template>
  <component
    :is="tag"
    class="stepper-head"
    :data-mdb-content="optional ? 'Optional' : null"
    @[clickEvent]="setCurrentActive"
  >
    <span v-if="icon" class="stepper-head-icon">{{ icon }}</span>
    <span v-else class="stepper-head-icon"><slot name="icon" /></span>
    <span class="stepper-head-text"><slot /></span>
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBStepperHead",
};
</script>

<script setup lang="ts">
import { computed, inject, toRefs, reactive } from "vue";
import { validateStep } from "./stepper-validation";

interface Step {
  completed: boolean;
  index: number;
  ref: HTMLElement | null;
  valid: boolean;
  validated: boolean;
  visited: boolean;
}

interface State {
  steps: Step[];
  count: number;
  activeStep: number;
  prevStep: number;
}

const defaultState = reactive({
  steps: [],
  count: 0,
  activeStep: 0,
  prevStep: 0,
});

defineProps({
  tag: {
    type: String,
    default: "div",
  },
  icon: String,
});

const state = inject<State>("state", defaultState);
const { activeStep, steps } = toRefs<State>(state);

const stepIndex = inject<number>("stepIndex", 0);
const setActiveStep = inject<(index: number) => void>("setActiveStep");
const setPrevStep = inject<(index: number) => void>("setPrevStep");
const linear = inject<boolean>("linear", false);
const noEditable = inject<boolean>("noEditable", false);
const setStepAttribute =
  inject<(index: number, attribute: string) => void>("setStepAttribute");
const optional = inject<boolean>("optional", false);
const emitEvent =
  inject<
    (
      stepIndex: number,
      type: "on-invalid" | "on-valid" | "on-change-step"
    ) => void
  >("emitEvent");
const disableStepperHeadClick = inject<boolean>("disableStepperHeadClick");

const setStepValid =
  inject<(index: number, valid: boolean) => void>("setStepValid");

const checkPrevValid = (stepIndex: number) => {
  const results = [];
  for (let i = 0; i <= stepIndex; i++) {
    if (i !== activeStep.value) {
      const isValid = state?.steps[i].validated && state.steps[i].valid;

      if (!isValid) {
        steps.value[i].ref?.classList.add("stepper-invalid");
      }

      results.push(isValid);
    }
  }
  return results.every((result) => result);
};

const setCurrentActive = () => {
  let isValid = true;
  let arePrevValid = true;

  if (linear) {
    isValid = validateStep(activeStep.value, steps.value, stepIndex, linear);
    setStepValid?.(activeStep.value, isValid);
  }
  if (linear && stepIndex > activeStep.value) {
    setStepAttribute?.(activeStep.value, "validated");
    arePrevValid = checkPrevValid(stepIndex - 1);

    if (!isValid || !arePrevValid) {
      emitEvent?.(activeStep.value, "on-invalid");
      return;
    }
  }

  if (noEditable) {
    setStepAttribute?.(activeStep.value, "visited");
    const isVisited = steps.value[stepIndex].visited;

    if (isVisited) {
      return;
    }
  }
  stepIndex !== activeStep.value && setPrevStep?.(activeStep.value);

  if (setActiveStep) {
    setActiveStep(stepIndex);
  }
};

const clickEvent = computed(() => {
  return disableStepperHeadClick ? null : "click";
});
</script>
