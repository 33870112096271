import Chart, { ChartTypeRegistry, ChartData } from "chart.js/auto";
import type { Chart as ChartType, ChartConfiguration } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import chartsDefaultOptions from "./chartsDefaultOptions";
import merge, { Options } from "deepmerge";
import { ref } from "vue";

type DefaultOptions = typeof chartsDefaultOptions;
type ChartOptions = ChartConfiguration | {};
interface DefaultOptionsEnchanced extends DefaultOptions {
  [key: string]: any;
}
interface DeepmergeOptions extends Options {
  cloneUnlessOtherwiseSpecified: (item: object, options: object) => object;
}

function generateCharts(
  canvasRef: HTMLCanvasElement | HTMLElement,
  type: keyof ChartTypeRegistry,
  data: ChartData = { datasets: [] },
  options: ChartOptions = {}
) {
  const _data = ref(data);
  const _chart = ref<ChartType | null>(null);
  const _options = ref<ChartOptions | null>(null);
  const _plugins = ref<any[] | null>(null);

  setupCharts();

  function setupCharts() {
    _options.value = mergeOptions(options, type, chartsDefaultOptions);
  }

  function mergeOptions(
    options: ChartOptions,
    type: string,
    defaultOptions: DefaultOptionsEnchanced
  ) {
    if (!options) {
      options = {};
    }

    const mergeObjects = (
      target: ChartOptions[],
      source: ChartOptions[],
      options: DeepmergeOptions
    ) => {
      const destination = target.slice();
      source.forEach((item, index) => {
        const sourceItem = item as Object;
        if (typeof destination[index] === "undefined") {
          destination[index] = options.cloneUnlessOtherwiseSpecified(
            sourceItem,
            options
          );
        } else if (options.isMergeableObject?.(sourceItem)) {
          const targetOptions = target[index] || {};

          destination[index] = merge(targetOptions, sourceItem, options);
        } else if (target.indexOf(item) === -1) {
          destination.push(item);
        }
      });
      return destination;
    };

    return merge(defaultOptions[type], options, {
      arrayMerge: mergeObjects,
    });
  }

  function addDataLabels() {
    _plugins.value = [ChartDataLabels];
  }

  function renderChart() {
    destroyChart();

    if (canvasRef instanceof HTMLCanvasElement) {
      const options = {
        type,
        data: _data.value,
        options: _options.value,
        plugins: _plugins.value,
      } as ChartConfiguration;

      const context = canvasRef.getContext("2d");

      if (context) {
        const chart = new Chart(context, options) as any;
        _chart.value = chart;
      }
    }
  }

  function updateChart(data: ChartData, options: ChartOptions) {
    _options.value = mergeOptions(options, type, chartsDefaultOptions);
    _data.value = data;

    renderChart();
  }

  function destroyChart() {
    if (_chart.value) {
      _chart.value.destroy();
      _chart.value = null;
    }
  }

  return {
    setupCharts,
    renderChart,
    addDataLabels,
    destroyChart,
    updateChart,
  };
}

export default generateCharts;
