<template>
  <component
    :is="tag"
    ref="triggerEl"
    class="btn btn-primary popconfirm-toggle"
    v-bind="$attrs"
    @click="open"
  >
    <slot />
  </component>
  <div
    v-if="modal && isActive"
    :id="uid"
    ref="modalRef"
    class="popconfirm-backdrop"
  >
    <div
      :class="[popconfirmContainerClassName]"
      v-mdb-focus-trap
      v-mdb-click-outside="clickOutside"
    >
      <div class="popconfirm show">
        <div class="popconfirm-message">
          <span v-if="icon" class="popconfirm-icon-container"
            ><i :class="icon"
          /></span>
          <span class="popconfirm-message-text">{{
            !$slots.message ? message : null
          }}</span>
          <slot v-if="$slots.message" name="message" />
        </div>
        <div class="popconfirm-buttons-container">
          <MDBBtn
            color="flat"
            size="sm"
            :aria-label="cancelLabel"
            @click="handleButtonClick('cancel')"
            >{{ cancelText }}</MDBBtn
          >
          <MDBBtn
            color="primary"
            size="sm"
            :aria-label="confirmLabel"
            @click="handleButtonClick('confirm')"
            >{{ confirmText }}</MDBBtn
          >
        </div>
      </div>
    </div>
  </div>
  <teleport v-else to="body">
    <div
      v-if="isActive"
      :id="uid"
      ref="popperEl"
      v-mdb-click-outside="clickOutside"
      v-mdb-focus-trap
      :class="popconfirmContainerClassName"
    >
      <div :class="popconfirmClassName">
        <div class="popconfirm-message">
          <span v-if="icon" class="popconfirm-icon-container"
            ><i :class="icon"
          /></span>
          <span class="popconfirm-message-text">{{
            !$slots.message ? message : null
          }}</span>
          <slot v-if="$slots.message" name="message" />
        </div>
        <div class="popconfirm-buttons-container">
          <MDBBtn
            color="flat"
            size="sm"
            :aria-label="cancelLabel"
            @click="handleButtonClick('cancel')"
            >{{ cancelText }}</MDBBtn
          >
          <MDBBtn
            color="primary"
            size="sm"
            :aria-label="confirmLabel"
            @click="handleButtonClick('confirm')"
            >{{ confirmText }}</MDBBtn
          >
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
export default {
  name: "MDBPopconfirm",
};
</script>

<script setup lang="ts">
import { computed, nextTick, onUnmounted, ref, watch, watchEffect } from "vue";
import MDBPopper from "../../../../src/components/utils/MDBPopper";
import MDBBtn from "../../../../src/components/free/components/MDBBtn.vue";
import vMdbFocusTrap from "../../../../src/directives/free/mdbFocusTrap";
import vMdbClickOutside from "../../../../src/directives/free/mdbClickOutside";
import { getUID } from "../../../../src/components/utils/getUID";
import { on, off } from "../../../../src/components/utils/MDBEventHandlers";
import { useScrollbarWidth } from "../../../../src/components/utils/useScrollbarWidth";

const props = defineProps({
  tag: {
    type: String,
    default: "button",
  },
  message: {
    type: String,
    default: "Are you sure?",
  },
  cancelText: {
    type: String,
    default: "Cancel",
  },
  cancelLabel: {
    type: String,
    default: "Cancel",
  },
  confirmText: {
    type: String,
    default: "Ok",
  },
  confirmLabel: {
    type: String,
    default: "Confirm",
  },
  icon: {
    type: String,
    default: "",
  },
  modal: Boolean,
  offset: {
    type: String,
    default: "0, 5",
  },
  position: {
    type: String,
    default: "bottom",
  },
});
const emit = defineEmits(["confirm", "cancel"]);

const { beforeEnter } = useScrollbarWidth();

const isRTL = document.documentElement.dir === "rtl";

const popconfirmContainerClassName = computed(() => {
  return [props.modal ? "popconfirm-modal" : "popconfirm-popover", "shadow-4"];
});

const popconfirmClassName = computed(() => {
  return ["popconfirm", "fade", show.value];
});

const uid = getUID("MDBPopconfirm-");

const show = ref("");

const translatePositionValue = () => {
  switch (props.position) {
    case "top left":
      return isRTL ? "top-start" : "top-end";
    case "top":
      return "top";
    case "top right":
      return isRTL ? "top-end" : "top-start";
    case "bottom left":
      return isRTL ? "bottom-start" : "bottom-end";
    case "bottom":
      return "bottom";
    case "bottom right":
      return isRTL ? "bottom-end" : "bottom-start";
    case "left":
      return isRTL ? "right" : "left";
    case "left top":
      return isRTL ? "right-end" : "left-end";
    case "left bottom":
      return isRTL ? "right-start" : "left-start";
    case "right":
      return isRTL ? "left" : "right";
    case "right top":
      return isRTL ? "left-end" : "right-end";
    case "right bottom":
      return isRTL ? "left-start" : "right-start";
    case undefined:
      return "bottom";
    default:
      return props.position;
  }
};

const modalRef = ref<HTMLElement | null>(null);

const { setPopper, isPopperActive, openPopper, closePopper, getPopperOffset } =
  MDBPopper();
const triggerEl = ref<HTMLElement | null>(null);
const popperEl = ref<HTMLElement | null>(null);

const popperSetup = () => {
  const placement = translatePositionValue();

  const config = {
    placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: getPopperOffset(props.offset, modalRef.value as HTMLElement),
        },
      },
    ],
  };

  setPopper(
    triggerEl.value as HTMLElement,
    popperEl.value as HTMLElement,
    config
  );
};

const isActive = ref(false);
const defaultBodyPaddingRight = ref("");

watch(
  () => isActive.value,
  (cur) => {
    if (cur && props.modal) {
      defaultBodyPaddingRight.value = document.body.style.paddingRight;
      beforeEnter();
    } else if (!cur && props.modal) {
      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = defaultBodyPaddingRight.value;
    }
  }
);

watchEffect(() => {
  if (isActive.value && !props.modal) {
    nextTick(() => {
      popperSetup();

      openPopper();
      setTimeout(() => {
        show.value = "show";
      }, 0);

      on(window, "keydown", handleEscapeKey);
    });
  } else {
    if (!isPopperActive.value) {
      return;
    }
    show.value = "";
    setTimeout(closePopper, 150);
    off(window, "keydown", handleEscapeKey);
  }
});

const open = () => {
  if (!isActive.value) {
    isActive.value = true;
  }
};

const close = () => {
  isActive.value = false;
};

const clickOutside = (event: Event) => {
  const isButton = triggerEl.value === event.target;
  const isPopover = popperEl.value === event.target;
  const isPopoverContent =
    popperEl.value &&
    (popperEl.value as HTMLElement).contains(event.target as HTMLElement);

  if (!isButton && !isPopover && !isPopoverContent) {
    close();
    emit("cancel");
  }
};

const handleEscapeKey = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    close();
    emit("cancel");
  }
};

const handleButtonClick = (event: "confirm" | "cancel") => {
  emit(event);
  close();
};

onUnmounted(() => {
  off(window, "keydown", handleEscapeKey);
});
</script>
