<template>
  <Transition :appear="autoInit" :name="computedName">
    <slot />
  </Transition>
</template>

<script setup lang="ts">
import { computed } from "vue";
const props = defineProps({
  name: String,
  autoInit: {
    type: Boolean,
    default: false,
  },
});

const omitAddingPrefixFor = ["fade-in", "fade-in-out", "browse-out"];

const computedName = computed(() => {
  return omitAddingPrefixFor.includes(props.name)
    ? props.name
    : "transition-" + props.name;
});
</script>
