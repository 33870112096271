<template>
  <component
    :is="tag"
    v-if="vertical || (!showAnimation && (isActive || shouldHide))"
    ref="contentRef"
    :style="vertical && heightStyle"
    :class="className"
  >
    <slot />
  </component>
  <MDBAnimation
    v-else-if="!vertical && showAnimation && (isActive || shouldHide)"
    ref="contentRef"
    :tag="tag"
    :class="className"
    trigger="onLoad"
    reset
    :animation="animation"
    :duration="800"
  >
    <slot />
  </MDBAnimation>
</template>

<script lang="ts">
export default {
  name: "MDBStepperContent",
};
</script>

<script setup lang="ts">
import MDBAnimation from "../../content-styles/MDBAnimation.vue";
import {
  computed,
  inject,
  nextTick,
  onMounted,
  ref,
  toRefs,
  watch,
  reactive,
} from "vue";
import type { ComputedRef } from "vue";

interface Step {
  completed: boolean;
  index: number;
  ref: HTMLElement | null;
  valid: boolean;
  validated: boolean;
  visited: boolean;
}

interface State {
  steps: Step[];
  count: number;
  activeStep: number;
  prevStep: number;
}

interface HTMLElementWithAnimation extends HTMLElement {
  startAnimation: () => void;
  stopAnimation: () => void;
}

const defaultState = reactive({
  steps: [],
  count: 0,
  activeStep: 0,
  prevStep: 0,
});

defineProps({
  tag: {
    type: String,
    default: "div",
  },
});

const className = computed(() => {
  return [
    "stepper-content",
    "py-3",
    vertical && vertical.value && !isActive.value && "stepper-content-hide",
  ];
});

const height = ref("0px");
const heightStyle = computed(() => ({
  height: height.value,
}));

const contentRef = ref<HTMLElement | HTMLElementWithAnimation | null>(null);
const { activeStep, prevStep } = toRefs<State>(inject("state", defaultState));

const stepIndex = inject<number | null>("stepIndex", null);
const showAnimation = inject<boolean>("showAnimation", true);
const elementPaddingY = 16;

const shouldBeActive = computed(() => activeStep.value === stepIndex);
const isActive = ref(shouldBeActive.value);
const shouldHide = ref<boolean>();

const vertical = inject<ComputedRef<boolean> | false>("vertical", false);

const animation = computed(() => {
  return shouldHide.value
    ? activeStep.value > prevStep.value
      ? "slide-out-left"
      : activeStep.value < prevStep.value
      ? "slide-out-right"
      : ""
    : (prevStep.value || prevStep.value === 0) &&
      activeStep.value > prevStep.value
    ? "slide-in-right"
    : activeStep.value < prevStep.value
    ? "slide-in-left"
    : "";
});

const setHeight = () => {
  height.value = stepHeight.value ? `${stepHeight.value}px` : "100%";
  changeFinished.value = true;
};

const stepHeight = ref(0);
const changeFinished = ref(true);

watch(
  () => shouldBeActive.value,
  (cur) => {
    if (vertical && !vertical.value) {
      isActive.value = cur;
      return;
    }
    if (!cur && changeFinished.value) {
      const stepContent = contentRef.value as HTMLElement;
      stepHeight.value = stepContent.scrollHeight;

      (contentRef.value as HTMLElement).style.transition = "none";

      setHeight();
    }
    setTimeout(() => {
      if (showAnimation) {
        (contentRef.value as HTMLElement).style.transition =
          "height 0.3s ease-in-out, margin-top 0.3s ease-in-out, margin-bottom 0.3s ease-in-out, padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out";
      }
      isActive.value = cur;
    });
  }
);

watch(
  () => isActive.value,
  (cur) => {
    if (vertical && vertical.value && cur) {
      changeFinished.value = false;
      setTimeout(setHeight, 250);
    }
  }
);

if (vertical) {
  watch(
    () => vertical.value,
    (cur) => {
      nextTick(() => {
        if (cur && contentRef.value) {
          setTimeout(setHeight, 250);
        }
      });
    }
  );
}

watch(
  () => prevStep.value,
  (cur) => {
    if ((vertical && vertical.value) || !showAnimation) {
      return;
    }
    if (contentRef.value && cur === stepIndex) {
      shouldHide.value = true;
      (contentRef.value as HTMLElementWithAnimation).startAnimation();

      setTimeout(
        () => {
          shouldHide.value = false;
        },
        window.matchMedia("(prefers-reduced-motion: reduce)").matches ? 0 : 800
      );
    }
  },
  { immediate: true }
);

onMounted(() => {
  if (vertical && vertical.value && contentRef.value) {
    if (!showAnimation) {
      contentRef.value.style.transition = "none";
    }
    if (!isActive.value) {
      stepHeight.value = contentRef.value.scrollHeight + 2 * elementPaddingY;
    }
    setHeight();
  }
});
</script>
