<template>
  <MDBInput
    ref="autocompleteWrapperRef"
    v-model="inputValue"
    type="text"
    labelClass="autocomplete-label"
    :class="['autocomplete-input', isPopperActive && 'focused']"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    :size="size"
    :aria-disabled="disabled"
    :aria-expanded="isDropdownActive"
    :aria-required="isValidated && required"
    :validFeedback="validFeedback"
    :invalidFeedback="invalidFeedback"
    :required="required"
    role="combobox"
    aria-haspopup="true"
    v-bind="$attrs"
    @focus="toggle"
    @keydown="handleOpenKeydown"
    @touchstart.stop
    @clickOutside="handleClickOutside"
    :isValidated="isSelectValidated"
    :isValid="isSelectValid"
  >
    <div v-if="isLoadingData" class="autocomplete-loader spinner-border">
      <span className="sr-only">Loading...</span>
    </div>
  </MDBInput>
  <teleport to="body">
    <div
      v-if="
        isDropdownActive &&
        threshold !== undefined &&
        inputValue.length >= threshold
      "
      :id="dropdownId"
      ref="dropdownRef"
      class="autocomplete-dropdown-container"
      :style="autocompleteDropdownContainerStyle"
    >
      <div
        class="autocomplete-dropdown"
        :class="isPopperActive && 'open'"
        tabindex="0"
      >
        <ul class="autocomplete-items-list" :style="autocompleteItemsListStyle">
          <li
            v-if="filteredData.length === 0"
            class="autocomplete-item autocomplete-no-results"
            :style="{ height: `px` }"
            @click.stop
          >
            {{ noResultsText }}
          </li>
          <li
            v-for="(item, i) in filteredData"
            :key="i"
            class="autocomplete-item"
            :class="[activeItem === i && 'active']"
            :style="{ height: `px` }"
            role="option"
            @click.stop="handleItemClick(i)"
          >
            {{ itemContent ? null : displayValue ? displayValue(item) : item }}
            <div v-if="itemContent" v-html="itemContent(item)"></div>
          </li>
          <div
            v-if="$slots.default"
            class="autocomplete-custom-content"
            @click.stop
            @touchstart.stop
          >
            <slot></slot>
          </div>
        </ul>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
export default {
  name: "MDBAutocomplete",
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { computed, ref, watch, nextTick, watchEffect } from "vue";
import MDBInput from "../../../../src/components/free/forms/MDBInput.vue";
import MDBPopper from "../../../../src/components/utils/MDBPopper";
import { getUID } from "../../../../src/components/utils/getUID";
import {
  ESCAPE,
  UP_ARROW,
  DOWN_ARROW,
  HOME,
  END,
  ENTER,
  TAB,
} from "../../../../src/components/utils/MDBKeycodes";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  filter: Function,
  displayValue: Function,
  itemContent: Function,
  noResultsText: {
    type: String,
    default: "No results",
  },
  threshold: {
    type: Number,
    default: 0,
  },
  optionHeight: {
    type: Number,
    default: 38,
  },
  visibleOptions: {
    type: Number,
    default: 5,
  },
  // input specific props
  label: String,
  placeholder: String,
  disabled: Boolean,
  required: Boolean,
  size: String,
  isValidated: Boolean,
  isValid: Boolean,
  validFeedback: String,
  invalidFeedback: String,
  autoSelect: Boolean,
  keepOpen: Boolean,
});

const emit = defineEmits([
  "update:modelValue",
  "open",
  "close",
  "update",
  "item-select",
]);

// Class & styles ------------------------
const autocompleteDropdownContainerStyle = computed(() => {
  return {
    width: dropdownWidth.value,
  };
});

const autocompleteItemsListStyle = computed(() => {
  return {
    maxHeight: `${props.visibleOptions * props.optionHeight}px`,
  };
});

// Config ------------------------
const autocompleteWrapperRef = ref<InstanceType<typeof MDBInput> | null>(null);
const dropdownRef = ref<HTMLElement | null>(null);
const dropdownId = getUID("MDBAutocompleteDropdown-");
const dropdownWidth = ref("200px");
const isDropdownActive = ref(false);
const popperConfig = {
  placement: "bottom-start",
  eventsEnabled: true,
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, 1],
      },
    },
  ],
};

// Data ------------------------
const filteredData = ref<any[]>([]);
const activeItem = ref(-1);
const closeTimeout = ref<ReturnType<typeof setInterval> | undefined>(undefined);

// Popper ------------------------
const { setPopper, isPopperActive, closePopper, openPopper } = MDBPopper();

// Public methods ------------------------
const toggle = () => {
  if (isPopperActive.value) {
    !props.keepOpen && close();
  } else {
    open();
  }
};

const canClose = ref(true);

const close = () => {
  if (!isPopperActive.value || !canClose.value) {
    return;
  }
  closePopper();
  emit("close");
  closeTimeout.value = setTimeout(() => {
    isDropdownActive.value = false;
  }, 300);
};

const open = () => {
  clearTimeout(closeTimeout.value);

  if (
    props.threshold !== undefined &&
    inputValue.value.length >= props.threshold
  ) {
    isDropdownActive.value = true;
    nextTick(() => openDropdown());
    canClose.value = false;
    setTimeout(() => (canClose.value = true), 50);
  }
};

// Private methods ------------------------
const inputValue = ref(props.modelValue);

const updateModelValue = () => {
  emit("update:modelValue", inputValue.value);
};

const handleClickOutside = () => {
  if (props.keepOpen) {
    return;
  }
  close();
};

const handleItemClick = (itemIndex: number) => {
  activeItem.value = itemIndex;
  const item = filteredData.value[itemIndex];

  inputValue.value = props.displayValue ? props.displayValue(item) : item;
  emit("item-select", item);
  !props.keepOpen && close();
};

const openDropdown = () => {
  if (!dropdownRef.value || !autocompleteWrapperRef.value?.inputRef) {
    return;
  }
  setPopper(
    autocompleteWrapperRef.value.inputRef,
    dropdownRef.value,
    popperConfig
  );
  openPopper();

  handleFilter();

  dropdownWidth.value = `${autocompleteWrapperRef.value.inputRef.offsetWidth}px`;

  emit("open");
};

const scrollToItem = () => {
  if (activeItem.value > -1) {
    const list = dropdownRef.value?.querySelector("ul");
    if (list) {
      const listHeight = list.offsetHeight;
      const listElements = dropdownRef.value?.querySelectorAll("li");
      if (listElements) {
        const item = listElements[activeItem.value];
        const itemHeight = item.offsetHeight;
        const scrollTop = list.scrollTop;

        const itemOffset = activeItem.value * itemHeight;
        const isBelow = itemOffset + itemHeight > scrollTop + listHeight;
        const isAbove = itemOffset < scrollTop;

        if (isAbove) {
          list.scrollTop = itemOffset;
        } else if (isBelow) {
          list.scrollTop = itemOffset - listHeight + itemHeight;
        } else {
          list.scrollTop = scrollTop;
        }
      }
    }
  }
};

// Keyboard accessibility ------------------------

const handleOpenKeydown = (event: KeyboardEvent) => {
  const key = event.keyCode;
  const isCloseKey =
    key === ESCAPE || (key === UP_ARROW && event.altKey) || key === TAB;

  if (isCloseKey) {
    if (key === TAB && props.autoSelect) {
      if (activeItem.value > -1) {
        handleItemClick(activeItem.value);
      }
    }
    !props.keepOpen && close();
    autocompleteWrapperRef.value?.inputRef?.focus();
    return;
  }

  switch (key) {
    case DOWN_ARROW:
      activeItem.value =
        activeItem.value < filteredData.value.length - 1
          ? activeItem.value + 1
          : activeItem.value;
      break;
    case UP_ARROW:
      activeItem.value =
        activeItem.value > 0 ? activeItem.value - 1 : activeItem.value;

      break;
    case HOME:
      activeItem.value = 0;
      break;
    case END:
      activeItem.value = filteredData.value.length - 1;
      break;

    case ENTER:
      if (activeItem.value > -1) {
        handleItemClick(activeItem.value);
      }
      return;
    default:
      return;
  }
};

// Validation ------------------------
const isSelectValidated = ref(props.isValidated);
const isSelectValid = ref(props.isValid);

// Filtering ------------------------
const isLoadingData = ref(false);

const handleFilter = () => {
  const isPromise = (value: any[] | Promise<any[]>) => {
    return !!value && !Array.isArray(value) && typeof value.then === "function";
  };

  if (isPopperActive && props.filter) {
    const data = props.filter(inputValue.value);
    if (isPromise(data)) {
      isLoadingData.value = true;
      data.then((items: any[]) => {
        filteredData.value = items;
        emit("update", items);

        isLoadingData.value = false;
      });
    } else {
      emit("update", data);

      filteredData.value = data;
    }
  }
};

// Watchers ----------------------
watchEffect(() => (inputValue.value = props.modelValue));

watch(
  () => inputValue.value,
  () => {
    if (
      !isDropdownActive.value &&
      props.threshold !== undefined &&
      inputValue.value.length >= props.threshold
    ) {
      open();
    }

    if (
      isDropdownActive.value &&
      props.threshold !== undefined &&
      inputValue.value.length < props.threshold
    ) {
      !props.keepOpen && close();
    }

    activeItem.value = -1;
    updateModelValue();
    handleFilter();
  }
);

watch(
  () => [activeItem.value, isPopperActive],
  () => scrollToItem()
);

watch(
  () => props.isValidated,
  (value) => (isSelectValidated.value = value)
);

watch(
  () => props.isValid,
  (value) => (isSelectValid.value = value)
);

defineExpose({
  open,
  close,
  toggle,
});
</script>
