<template>
  <MDBNavbar v-bind="$props" :classNavbar="className">
    <slot />
  </MDBNavbar>
</template>

<script lang="ts">
export default {
  name: "MDBNavbar",
};
</script>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import MDBNavbar from "../../../../src/components/free/navigation/MDBNavbar.vue";
import { on, off } from "../../../../src/components/utils/MDBEventHandlers";

const props = defineProps({
  tag: {
    type: String,
    default: "nav",
  },
  bg: {
    type: String,
  },
  dark: {
    type: Boolean,
    default: false,
  },
  light: {
    type: Boolean,
    default: false,
  },
  double: {
    type: Boolean,
    default: false,
  },
  expand: {
    type: String,
  },
  position: {
    type: String,
  },
  transparent: {
    type: Boolean,
    default: false,
  },
  scrolling: {
    type: Boolean,
    default: false,
  },
  scrollingOffset: {
    type: Number,
    default: 100,
  },
  center: {
    type: Boolean,
    default: false,
  },
  container: {
    type: [Boolean, String],
    default: false,
  },
  classContainer: {
    type: String,
  },
  classNavbar: String,
  scroll: Boolean,
});

const isScrolled = ref(false);

const className = computed(() => {
  return [
    props.scroll && "navbar-scroll",
    isScrolled.value ? "navbar-scrolled" : null,
    props.classNavbar,
  ].join(" ");
});
const handleScroll = () => {
  if (window.scrollY > 0) {
    isScrolled.value = true;
  } else {
    isScrolled.value = false;
  }
};

onMounted(() => {
  on(window, "scroll", handleScroll);
});

onUnmounted(() => {
  off(window, "scroll", handleScroll);
});
</script>
