<template>
  <div class="form-outline datepicker" ref="datepickerRef">
    <MDBInput
      v-bind="$attrs"
      v-model="inputValue"
      :wrap="false"
      aria-haspopup="dialog"
      :isValid="isValid"
      :isValidated="validated"
      :invalidFeedback="invalidFeedback ? invalidFeedback : invalidLabel"
      :validFeedback="validLabel || validFeedback"
      :disabled="disabled"
      @click="handleInputToggle"
      @keydown.enter="handleInputToggle"
    />
    <button
      v-if="isToggleButtonActive"
      ref="toggleButtonRef"
      type="button"
      class="datepicker-toggle-button"
      aria-haspopup="dialog"
      @click="isActive = !isActive"
      v-html="toggleIcon"
    ></button>

    <teleport v-if="inline" :to="container">
      <transition name="datepicker-fade">
        <div
          v-if="isActive"
          ref="datepickerInlineRef"
          v-mdb-click-outside="handleClickOutside"
          class="datepicker-dropdown-container"
        >
          <MDBDatepickerMain inline />
        </div>
      </transition>
    </teleport>

    <MDBDatepickerModal v-else v-model="isActive" @cancel="handleClickOutside">
      <template
        #header="{ selected, headerWeekday, headerMonth, headerMonthday }"
      >
        <slot
          name="header"
          :headerWeekday="headerWeekday"
          :headerMonth="headerMonth"
          :headerMonthday="headerMonthday"
          :selected="selected"
        ></slot>
      </template>
    </MDBDatepickerModal>
  </div>
</template>

<script lang="ts">
export default {
  name: "MDBDatepicker",
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import {
  ref,
  computed,
  provide,
  onMounted,
  onUnmounted,
  watch,
  nextTick,
  inject,
  watchEffect,
  toRefs,
  PropType,
} from "vue";

import type { Ref } from "vue";

import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isoWeek from "dayjs/plugin/isoWeek";
import advancedFormat from "dayjs/plugin/advancedFormat";
import MDBDatepickerModal from "./MDBDatepickerModal.vue";
import MDBDatepickerMain from "./MDBDatepickerMain.vue";
import MDBInput from "../../../../../src/components/free/forms/MDBInput.vue";
import MDBPopper from "../../../../../src/components/utils/MDBPopper";
import vMdbClickOutside from "../../../../../src/directives/free/mdbClickOutside";
import { on, off } from "../../../../../src/components/utils/MDBEventHandlers";

interface Day {
  current?: boolean;
  dayJS?: dayjs.Dayjs;
  disabled?: boolean;
  focused?: boolean;
  label?: string;
  selectable?: boolean;
  selected?: boolean;
}

interface DateTimepicker {
  inputRef: HTMLElement;
}

dayjs.extend(isToday);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);

const props = defineProps({
  cancelBtnLabel: {
    type: String,
    default: "Cancel selection",
  },
  cancelBtnText: {
    type: String,
    default: "Cancel",
  },
  clearBtnLabel: {
    type: String,
    default: "Clear selection",
  },
  clearBtnText: {
    type: String,
    default: "Clear",
  },
  container: {
    type: String,
    default: "body",
  },
  filter: {
    type: Function,
    default: () => false,
  },
  format: {
    type: String,
    default: "DD/MM/YYYY",
  },
  inline: Boolean,
  invalidFeedback: String,
  invalidLabel: {
    type: String,
    default: "Invalid date or date format",
  },
  inputToggle: Boolean,
  isValid: Boolean,
  isValidated: Boolean,
  max: String,
  min: String,
  modelValue: String,
  monthsFull: {
    type: Array as PropType<string[]>,
    default: () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  },
  monthsShort: {
    type: Array as PropType<string[]>,
    default: () => [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  nextMonthLabel: {
    type: String,
    default: "Next month",
  },
  nextMultiYearLabel: {
    type: String,
    default: "Next 24 years",
  },
  nextYearLabel: {
    type: String,
    default: "Next year",
  },
  okBtnLabel: {
    type: String,
    default: "Confirm selection",
  },
  okBtnText: {
    type: String,
    default: "Ok",
  },
  prevMonthLabel: {
    type: String,
    default: "Previous month",
  },
  prevMultiYearLabel: {
    type: String,
    default: "Previous 24 years",
  },
  prevYearLabel: {
    type: String,
    default: "Previous year",
  },
  startDate: String,
  startDay: {
    type: Number,
    default: 0,
  },
  switchToMultiYearViewLabel: {
    type: String,
    default: "Choose year and month",
  },
  switchToDayViewLabel: {
    type: String,
    default: "Choose date",
  },
  title: {
    type: String,
    default: "Select date",
  },
  toggleButton: {
    type: Boolean,
    default: true,
  },
  toggleDatepicker: Function,
  toggleIcon: {
    type: String,
    default: "<i class='far fa-calendar datepicker-toggle-icon'></i>",
  },
  validate: Boolean,
  validLabel: {
    type: String,
  },
  validFeedback: String,
  view: {
    type: String,
    default: "day",
  },
  weekdaysFull: {
    type: Array as PropType<string[]>,
    default: () => [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
  },
  weekdaysNarrow: {
    type: Array as PropType<string[]>,
    default: () => ["S", "M", "T", "W", "T", "F", "S"],
  },
  weekdaysShort: {
    type: Array as PropType<string[]>,
    default: () => ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  },
  disabled: Boolean,
  disablePast: {
    type: Boolean,
    default: false,
  },
  disableFuture: {
    type: Boolean,
    default: false,
  },
  confirmDateOnSelect: Boolean,
  removeClearBtn: Boolean,
  removeCancelBtn: Boolean,
  removeOkBtn: Boolean,
  customHeader: Boolean,
});

const emit = defineEmits([
  "update:modelValue",
  "update:isValid",
  "update:isValidated",
  "close",
  "open",
  "cancel",
]);

const inputValue = ref<string | undefined>(props.modelValue || "");
const isActive = ref(false);
const view = ref(props.view);

// ------------- VALIDATION -------------
const valid = ref(props.isValid);
const validated = ref(props.isValidated);
const validate = ref(props.validate);

watchEffect(() => {
  validate.value = props.validate;
  if (!validate.value) {
    validated.value = false;
    valid.value = false;
  }
});

watchEffect(() => {
  if (validate.value) {
    valid.value = props.isValid;
  }
});
watchEffect(() => {
  if (validate.value) {
    validated.value = props.isValidated;
  }
});

const minDate = ref(props.min ? dayjs(props.min, props.format) : "");
const maxDate = ref(props.max ? dayjs(props.max, props.format) : "");
const startDate = ref(
  props.startDate ? dayjs(props.startDate, props.format) : ""
);
const today = dayjs();

const allowedDate = (date: dayjs.Dayjs | string) => {
  if (props.disablePast) {
    if (startDate.value) {
      typeof date !== "string" && date.isSameOrAfter(startDate.value);
    } else {
      typeof date !== "string" && date.add(1, "day").isSameOrAfter(today);
    }
  } else if (props.disableFuture) {
    typeof date !== "string" && date.isSameOrBefore(startDate.value || today);
  } else if (minDate.value && maxDate.value && startDate.value) {
    return startDate.value &&
      typeof date !== "string" &&
      date.isSameOrBefore(maxDate.value) &&
      date.isSameOrAfter(minDate.value)
      ? date
      : startDate.value;
  } else if (minDate.value && maxDate.value) {
    return typeof date !== "string" &&
      date.isAfter(minDate.value) &&
      date.isBefore(maxDate.value)
      ? date
      : minDate.value;
  } else if (maxDate.value && startDate.value) {
    return typeof date !== "string" &&
      date.isSameOrBefore(startDate.value) &&
      date.isSameOrBefore(maxDate.value)
      ? date
      : startDate.value;
  } else if (minDate.value && startDate.value) {
    return typeof date !== "string" &&
      date.isSameOrAfter(startDate.value) &&
      date.isSameOrAfter(minDate.value)
      ? startDate.value
      : date;
  } else if (minDate.value) {
    return typeof date !== "string" && date.isSameOrAfter(minDate.value)
      ? date
      : minDate.value;
  } else if (maxDate.value) {
    return typeof date !== "string" && date.isSameOrBefore(maxDate.value)
      ? date
      : maxDate.value;
  }

  return date;
};

const isInRange = (date: dayjs.Dayjs) => {
  if (props.disablePast) {
    if (startDate.value) {
      return date.isSameOrAfter(startDate.value);
    } else {
      return date.add(1, "day").isSameOrAfter(today);
    }
  } else if (props.disableFuture) {
    return date.isSameOrBefore(startDate.value || today);
  } else if (minDate.value && maxDate.value && startDate.value) {
    return (
      startDate.value &&
      date.isSameOrBefore(maxDate.value) &&
      date.isSameOrAfter(minDate.value)
    );
  } else if (minDate.value && maxDate.value) {
    return (
      date.isSameOrAfter(minDate.value) && date.isSameOrBefore(maxDate.value)
    );
  } else if (maxDate.value && startDate.value) {
    return startDate.value && date.isSameOrBefore(maxDate.value);
  } else if (minDate.value && startDate.value) {
    return startDate.value && date.isSameOrAfter(minDate.value);
  } else if (minDate.value) {
    return date.isSameOrAfter(minDate.value);
  } else if (maxDate.value) {
    return date.isSameOrBefore(maxDate.value);
  }

  return true;
};

const isDateValid = (
  date: dayjs.Dayjs | string,
  clear: boolean | undefined = undefined
) => {
  const isValid = dayjs(date, props.format).isValid();
  if (date !== "" && (!isValid || !isInRange(dayjs(date, props.format)))) {
    if (validate.value) {
      validated.value = true;
      valid.value = false;
    }

    emit("update:isValid", false);
    emit("update:isValidated", true);

    return false;
  } else {
    if (validate.value) {
      // if clear or no input value - we do not want to show that is ok - just clear previous state
      validated.value =
        date === "" ? false : props.validLabel ? true : clear ? false : true;
      valid.value = true;
    }

    emit("update:isValid", true);
    emit("update:isValidated", validated.value);

    return true;
  }
};

const setInitialValue = () => {
  const fallbackDate = allowedDate(
    startDate.value || today || minDate.value || maxDate.value
  );
  return inputValue.value === ""
    ? dayjs(fallbackDate, props.format)
    : isDateValid(inputValue.value as string)
    ? dayjs(inputValue.value, props.format)
    : dayjs(fallbackDate, props.format);
};

const activeDate = ref(setInitialValue());
const headerDate = ref(activeDate.value);
const selectedDate = ref(
  dayjs(inputValue.value, props.format).isValid() ? headerDate.value : null
);
const focusedDate = ref(selectedDate.value || activeDate.value);
const isToggleButtonActive = computed(() =>
  props.disabled ? false : props.toggleButton
);

watch(
  () => props.min,
  (cur) => {
    minDate.value = cur ? dayjs(cur, props.format) : "";
  }
);
watch(
  () => props.max,
  (cur) => {
    maxDate.value = cur ? dayjs(cur, props.format) : "";
  }
);

const goToPrevMonth = () => {
  activeDate.value = activeDate.value.subtract(1, "month");
  focusedDate.value = activeDate.value;
};
const goToNextMonth = () => {
  activeDate.value = activeDate.value.add(1, "month");
  focusedDate.value = activeDate.value;
};
const selectDay = (day: Day) => {
  headerDate.value = dayjs(day.dayJS);
  selectedDate.value = headerDate.value;
  focusedDate.value = headerDate.value;

  if (props.inline || props.confirmDateOnSelect) {
    ok();
  }
};
const changeActiveYear = (year: number) => {
  headerDate.value = headerDate.value.year(year);
  activeDate.value = headerDate.value;
  focusedDate.value = headerDate.value;
};
const changeActiveMonth = (month: number) => {
  headerDate.value = headerDate.value.month(month);
  activeDate.value = headerDate.value;
  focusedDate.value = headerDate.value;
};
const changeView = (newView: string) => {
  view.value = newView;
};

const ok = () => {
  updateInputValue();
  isActive.value = false;
};
const cancel = () => {
  isActive.value = false;

  handleCancelValues();
};
const clear = () => {
  const validStartDate = allowedDate(
    startDate.value || today || minDate.value || maxDate.value
  );
  selectedDate.value = null;
  updateInputValue();

  activeDate.value = dayjs(inputValue.value, props.format).isValid()
    ? dayjs(inputValue.value, props.format)
    : dayjs(validStartDate);

  headerDate.value = activeDate.value;
  focusedDate.value = activeDate.value;
};

const updateInputValue = () => {
  if (selectedDate.value) {
    inputValue.value = selectedDate.value.format(props.format);
  } else {
    inputValue.value = undefined;
  }

  emit("update:modelValue", inputValue.value);
};

const handleCancelValues = () => {
  activeDate.value = setInitialValue();
  headerDate.value = dayjs(activeDate.value);
  selectedDate.value = null;
  focusedDate.value = activeDate.value;

  emit("cancel");
};

const handleClickOutside = () => {
  if (props.inline && !debounce.value) {
    emit("cancel");
    isActive.value = false;
  } else {
    handleCancelValues();
  }
};
const handleInputToggle = () => {
  if (props.inputToggle) {
    isActive.value = !isActive.value;
  }
};

// datetimepicker ---------------------------
const dateTimepicker = inject<Ref<DateTimepicker> | null>(
  "dateTimepicker",
  null
);

// popper ------------------------------------
const datepickerRef = ref<HTMLElement | null>(null);
const datepickerInlineRef = ref<HTMLElement | null>(null);
const toggleButtonRef = ref<HTMLButtonElement | null>(null);
const debounce = ref(true);

const triggerRef = computed(() => {
  if (props.inline && dateTimepicker) {
    return dateTimepicker.value.inputRef;
  }
  return datepickerRef.value;
});

if (props.inline) {
  const { setPopper, openPopper, closePopper } = MDBPopper();

  watch(
    () => isActive.value,
    (isActive) => {
      debounce.value = isActive;
      setTimeout(() => {
        debounce.value = !isActive;
      }, 10);
      if (isActive) {
        nextTick(() => {
          if (triggerRef.value && datepickerInlineRef.value) {
            setPopper(triggerRef.value, datepickerInlineRef.value, {
              placement: "bottom-start",
              eventsEnabled: true,
            });
            openPopper();
            emit("open");
          }
        });
      } else {
        closePopper();
        focusToggler();
        setTimeout(() => {
          setDefaults();
          emit("close");
        }, 400);
      }
    }
  );
} else {
  watch(
    () => isActive.value,
    (isActive) => {
      if (isActive) {
        emit("open");
      } else {
        focusToggler();
        setTimeout(() => {
          // clear();
          setDefaults();
          emit("close");
        }, 400);
      }
    }
  );
}

const focusToggler = () => {
  if (toggleButtonRef.value) {
    toggleButtonRef.value.focus();
  } else {
    datepickerRef.value && datepickerRef.value.querySelector("input")?.focus();
  }
};

const setDefaults = () => {
  const allowed = allowedDate(today) as dayjs.Dayjs;
  selectedDate.value = inputValue.value
    ? dayjs(inputValue.value, props.format)
    : null;
  activeDate.value = selectedDate.value ? selectedDate.value : allowed;
  focusedDate.value = activeDate.value;
  view.value = "day";
};

const setFocusedDate = (date: dayjs.Dayjs) => {
  focusedDate.value = date;
};

const activeDateObject = computed(() => activeDate.value);
const headerDateObject = computed(() => headerDate.value);
const selectedDateObject = computed(() => selectedDate.value);
const focusedDateObject = computed(() => focusedDate.value);

// monday first
const weekdaysNarrow = [...props.weekdaysNarrow];
const weekdaysFull = [...props.weekdaysFull];
if (props.startDay === 1) {
  weekdaysNarrow.push(weekdaysNarrow.shift() as string);
  weekdaysFull.push(weekdaysFull.shift() as string);
}

provide("activeDate", activeDateObject);
provide("headerDate", headerDateObject);
provide("selectedDate", selectedDateObject);
provide("focusedDate", focusedDateObject);
provide("setFocusedDate", setFocusedDate);
provide("inputValue", inputValue);
provide("monthsFull", props.monthsFull);
provide("monthsShort", props.monthsShort);
provide("title", props.title);
provide("weekdaysFull", weekdaysFull);
provide("weekdaysNarrow", weekdaysNarrow);
provide("weekdaysShort", props.weekdaysShort);
provide("goToPrevMonth", goToPrevMonth);
provide("goToNextMonth", goToNextMonth);
provide("selectDay", selectDay);
provide("changeActiveYear", changeActiveYear);
provide("changeActiveMonth", changeActiveMonth);
provide("ok", ok);
provide("cancel", cancel);
provide("clear", clear);
provide("view", view);
provide("today", today);
provide("changeView", changeView);
provide("cancelBtnText", props.cancelBtnText);
provide("clearBtnText", props.clearBtnText);
provide("okBtnText", props.okBtnText);
provide("minDate", minDate);
provide("maxDate", maxDate);
provide("startDate", startDate);
provide("filter", props.filter);
provide("startDay", props.startDay);
provide("disablePast", props.disablePast);
provide("disableFuture", props.disableFuture);

provide("container", props.container);
provide("cancelBtnLabel", props.cancelBtnLabel);
provide("clearBtnLabel", props.clearBtnLabel);
provide("okBtnLabel", props.okBtnLabel);
provide("nextMonthLabel", props.nextMonthLabel);
provide("prevMonthLabel", props.prevMonthLabel);
provide("nextYearLabel", props.nextYearLabel);
provide("prevYearLabel", props.prevYearLabel);
provide("nextMultiYearLabel", props.nextMultiYearLabel);
provide("prevMultiYearLabel", props.prevMultiYearLabel);
provide("switchToMultiYearViewLabel", props.switchToMultiYearViewLabel);
provide("switchToDayViewLabel", props.switchToDayViewLabel);

provide("removeClearBtn", props.removeClearBtn);
provide("removeCancelBtn", props.removeCancelBtn);
provide("removeOkBtn", props.removeOkBtn);
provide("customHeader", props.customHeader);

// keyboard accessibility -------------------------------------
const handleDayUp = () => {
  const prevWeek = focusedDate.value.subtract(1, "week");
  if (focusedDate.value.month() !== prevWeek.month()) {
    activeDate.value = prevWeek;
  }

  focusedDate.value = prevWeek;
};
const handleDayDown = () => {
  const nextWeek = focusedDate.value.add(1, "week");
  if (focusedDate.value.month() !== nextWeek.month()) {
    activeDate.value = nextWeek;
  }

  focusedDate.value = nextWeek;
};
const handleDayLeft = () => {
  const prevDay = focusedDate.value.subtract(1, "day");
  if (focusedDate.value.date() === 1) {
    activeDate.value = prevDay;
  }

  focusedDate.value = prevDay;
};
const handleDayRight = () => {
  const nextDay = focusedDate.value.add(1, "day");
  if (focusedDate.value.date() === dayjs(focusedDate.value).daysInMonth()) {
    activeDate.value = nextDay;
  }

  focusedDate.value = focusedDate.value.add(1, "day");
};
const handleDayHome = () => {
  focusedDate.value = focusedDate.value.startOf("month");
};
const handleDayEnd = () => {
  focusedDate.value = focusedDate.value.endOf("month");
};
const handleDayPageUp = () => {
  focusedDate.value = focusedDate.value.subtract(1, "month");
  activeDate.value = focusedDate.value;
};
const handleDayPageDown = () => {
  focusedDate.value = focusedDate.value.add(1, "month");
  activeDate.value = focusedDate.value;
};
const handleDecrementYear = (key: number) => {
  const decrementYear = focusedDate.value.subtract(key, "year");
  const startingYearRange = activeDate.value.year() - 5;
  if (decrementYear.year() < startingYearRange) {
    activeDate.value = activeDate.value.subtract(24, "year");
  }

  focusedDate.value = decrementYear;
};
const handleIncrementYear = (key: number) => {
  const incrementYear = focusedDate.value.add(key, "year");
  const endingYearRange = activeDate.value.year() + 18;
  if (incrementYear.year() > endingYearRange) {
    activeDate.value = activeDate.value.add(24, "year");
  }

  focusedDate.value = incrementYear;
};
const handleYearHome = () => {
  focusedDate.value = activeDate.value.subtract(5, "year");
};
const handleYearEnd = () => {
  focusedDate.value = activeDate.value.add(18, "year");
};
const handleYearPageUp = () => {
  activeDate.value = activeDate.value.subtract(24, "year");
  focusedDate.value = focusedDate.value.subtract(24, "year");
};
const handleYearPageDown = () => {
  activeDate.value = activeDate.value.add(24, "year");
  focusedDate.value = focusedDate.value.add(24, "year");
};
const handleDecrementMonth = (key: number) => {
  focusedDate.value = focusedDate.value.subtract(key, "month");
  activeDate.value = focusedDate.value;
};
const handleIncrementMonth = (key: number) => {
  focusedDate.value = focusedDate.value.add(key, "month");
  activeDate.value = focusedDate.value;
};
const handleMonthHome = () => {
  focusedDate.value = activeDate.value.startOf("year");
  activeDate.value = focusedDate.value;
};
const handleMonthEnd = () => {
  focusedDate.value = activeDate.value.endOf("year");
  activeDate.value = focusedDate.value;
};
const handleMonthPageUp = () => {
  activeDate.value = activeDate.value.subtract(1, "year");
  focusedDate.value = activeDate.value;
};
const handleMonthPageDown = () => {
  activeDate.value = activeDate.value.add(1, "year");
  focusedDate.value = activeDate.value;
};

provide("handleDayUp", handleDayUp);
provide("handleDayDown", handleDayDown);
provide("handleDayLeft", handleDayLeft);
provide("handleDayRight", handleDayRight);
provide("handleDayHome", handleDayHome);
provide("handleDayEnd", handleDayEnd);
provide("handleDayPageUp", handleDayPageUp);
provide("handleDayPageDown", handleDayPageDown);
provide("handleDecrementYear", handleDecrementYear);
provide("handleIncrementYear", handleIncrementYear);
provide("handleYearHome", handleYearHome);
provide("handleYearEnd", handleYearEnd);
provide("handleYearPageUp", handleYearPageUp);
provide("handleYearPageDown", handleYearPageDown);
provide("handleDecrementMonth", handleDecrementMonth);
provide("handleIncrementMonth", handleIncrementMonth);
provide("handleMonthHome", handleMonthHome);
provide("handleMonthEnd", handleMonthEnd);
provide("handleMonthPageUp", handleMonthPageUp);
provide("handleMonthPageDown", handleMonthPageDown);

const handleEsc = (event: KeyboardEvent) => {
  if (event.key === "Escape" && isActive.value) {
    cancel();
  }
};

onMounted(() => {
  on(document, "keydown", handleEsc);
});
onUnmounted(() => {
  off(document, "keydown", handleEsc);
});

// public methods
const open = () => {
  isActive.value = true;
};
const close = () => {
  isActive.value = false;
};

const resetDates = (date: dayjs.Dayjs | string | false = false) => {
  const fallbackDate = allowedDate(startDate.value || today);

  selectedDate.value = null;

  if (typeof fallbackDate !== "string") {
    headerDate.value = fallbackDate;
    activeDate.value = fallbackDate;
    focusedDate.value = fallbackDate;
  }

  if (date && typeof date === "string") {
    inputValue.value = date;
  }
};

// watch input manual changes
watch(
  () => inputValue.value,
  (date) => {
    if (date && isDateValid(date, true)) {
      selectedDate.value = dayjs(date, props.format);
      headerDate.value = dayjs(date, props.format);
      activeDate.value = dayjs(date, props.format);
      focusedDate.value = dayjs(date, props.format);
    } else {
      resetDates();

      emit("update:isValid", false);
      emit("update:isValidated", true);
    }

    emit("update:modelValue", inputValue.value);
  }
);

const modelValueProp = toRefs(props).modelValue as Ref<string>;

watch(
  () => modelValueProp.value,
  (date) => {
    if (isDateValid(date, true)) {
      if (date !== "") {
        selectedDate.value = dayjs(date, props.format);
        headerDate.value = dayjs(date, props.format);
        activeDate.value = dayjs(date, props.format);
        focusedDate.value = dayjs(date, props.format);
        inputValue.value = date;
      } else {
        clear();
      }
    } else {
      resetDates(!validate.value && date);
      emit("update:isValid", false);
      emit("update:isValidated", true);
    }
  }
);

defineExpose({
  open,
  close,
});
</script>
