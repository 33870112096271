<template>
  <component :is="tag" :id="uid" ref="canvasRef" :class="canvasClass" />
</template>

<script lang="ts">
export default {
  name: "MDBChart",
};
</script>

<script setup lang="ts">
import generateCharts from "./generateCharts";
import { getUID } from "../../../../components/utils/getUID";
import { onMounted, ref, watch, PropType } from "vue";
import {
  ChartTypeRegistry,
  ChartData,
  ChartConfiguration,
} from "chart.js/auto";

const props = defineProps({
  tag: {
    type: String,
    default: "canvas",
  },
  type: String,
  data: Object as PropType<ChartData>,
  options: Object as PropType<ChartConfiguration["options"]>,
  datalabels: {
    type: Boolean,
    default: false,
  },
  id: String,
  canvasClass: String,
});

const canvasRef = ref<HTMLElement | HTMLCanvasElement | null>(null);
const uid = props.id || getUID("MDBChart-");

const setupChartsFunc = ref<(() => void) | null>(null);
const renderChartFunc = ref<(() => void) | null>(null);
const addDataLabelsFunc = ref<(() => void) | null>(null);
const updateChartFunc = ref<((...args: any[]) => void) | null>(null);

interface ChartsFunctions {
  setupCharts: () => void;
  renderChart: () => void;
  addDataLabels: () => void;
  updateChart: (...args: any[]) => void;
}

const getChartsFunctions = ({
  setupCharts,
  renderChart,
  addDataLabels,
  updateChart,
}: ChartsFunctions) => {
  setupChartsFunc.value = setupCharts;
  renderChartFunc.value = renderChart;
  addDataLabelsFunc.value = addDataLabels;
  updateChartFunc.value = updateChart;
};

watch(
  () => props.data,
  (newData: object) => {
    updateChartFunc.value?.(newData, props.options || {});
  }
);

watch(
  () => props.options,
  (newOptions: object) => {
    const data = props.data || {};
    updateChartFunc.value?.(data, newOptions);
  }
);

onMounted(() => {
  // chart functions must be initialized on mount to properly pass canvasRef value to Charts.js
  if (canvasRef.value) {
    const chartsFunctions = generateCharts(
      canvasRef.value,
      props.type as keyof ChartTypeRegistry,
      props.data,
      props.options
    );

    getChartsFunctions(chartsFunctions);

    if (props.datalabels) {
      addDataLabelsFunc.value?.();
    }

    renderChartFunc.value?.();
  }
});
</script>
