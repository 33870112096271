<template>
  <component
    :is="tag"
    class="timepicker-clock-wrapper d-flex justify-content-center flex-column align-items-center"
  >
    <MDBTimepickerClock
      v-if="unitsMode && unitsMode === 'hours'"
      v-model="hours"
      v-model:angle="angle"
      :unitsMode="unitsMode"
      :min="1"
      :max="hoursFormat"
      :allowedValues="allowedHours"
      :rotate="30"
      :double="double"
      @input="(e) => updateHoursValue?.(e)"
      @change="chageUnitsMode?.('minutes')"
    />
    <MDBTimepickerClock
      v-if="unitsMode && unitsMode === 'minutes'"
      v-model="minutes"
      v-model:angle="angle"
      :unitsMode="unitsMode"
      :min="0"
      :max="59"
      :allowedValues="allowedMinutes"
      @input="(e) => updateMinutesValue?.(e)"
    />
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBTimepickerPlate",
};
</script>

<script setup lang="ts">
import { computed, inject, ref } from "vue";
import type { ComputedRef, Ref } from "vue";
import MDBTimepickerClock from "./MDBTimepickerClock.vue";

defineProps({
  tag: {
    type: String,
    default: "div",
  },
});

// ------------- TIMEPICKER PROPS INJECTS -------------
const hoursFormat = inject<number>("hoursFormat", 12);

// ------------- STATE MANAGEMENT -------------
const hours = inject<ComputedRef<string | number> | undefined>(
  "hours",
  undefined
);
const minutes = inject<ComputedRef<string> | undefined>("minutes", undefined);
const unitsMode = inject<Ref<string> | undefined>("unitsMode", undefined);
const updateMinutesValue = inject<((value: number) => void) | null>(
  "updateMinutesValue",
  null
);
const updateHoursValue = inject<
  | ((
      delta: number,
      currentValue?: number,
      recursion?: undefined | boolean
    ) => number)
  | null
>("updateHoursValue", null);
const chageUnitsMode = inject<((value: string) => void) | null>(
  "chageUnitsMode",
  null
);

const double = computed(() => {
  return hoursFormat === 24;
});

const angle = ref(360);

// ------------- VALIDATION -------------
const allowedHours = inject<((value: number) => number | boolean) | undefined>(
  "allowedHours",
  undefined
);
const allowedMinutes = inject<((value: number) => boolean) | undefined>(
  "allowedMinutes",
  undefined
);
</script>
