<template>
  <transition :name="transitionName">
    <component
      v-show="isActive"
      :class="className"
      :is="tag"
      :id="id"
      v-bind="$attrs"
      ref="sidenav"
      @mouseenter="onMouseEnter"
      @mouseover="onMouseOver"
      @mouseleave="onMouseLeave"
      v-mdb-touch:swipe="{
        callback: swipe,
        direction: 'x',
      }"
      v-mdb-scroll-lock="scrollLock"
    >
      <MDBScrollbar
        disableKeyboard
        suppressScrollX
        :height="`${windowHeight}px`"
      >
        <slot />
      </MDBScrollbar>
    </component>
  </transition>
  <transition name="backdrop">
    <div
      :class="['sidenav-backdrop', backdropClass]"
      :style="backdropStyle"
      v-if="isBackdropActive"
      @click.self="hideSideNav"
    />
  </transition>
</template>

<script lang="ts">
export default {
  name: "MDBSideNav",
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import {
  computed,
  ref,
  onMounted,
  watch,
  onUnmounted,
  nextTick,
  provide,
  watchEffect,
  onBeforeMount,
} from "vue";
import { on, off } from "../../utils/MDBEventHandlers";
import vMdbTouch from "../../../../src/directives/pro/mdbTouch";
import vMdbScrollLock from "../../../../src/directives/pro/mdbScrollLock";
import MDBScrollbar from "../../../../src/components/pro/methods/MDBScrollbar.vue";

const props = defineProps({
  tag: {
    type: String,
    default: "nav",
  },
  color: {
    type: String,
    default: "primary",
  },
  classes: String,
  modelValue: Boolean,
  relative: {
    type: Boolean,
    default: false,
  },
  absolute: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String,
    default: "over",
    validator: (value: string) =>
      ["over", "side", "push"].indexOf(value.toLowerCase()) > -1,
  },
  light: {
    type: Boolean,
    defualt: false,
  },
  dark: {
    type: Boolean,
    default: false,
  },
  right: {
    type: Boolean,
    default: false,
  },
  slim: {
    type: Boolean,
    default: false,
  },
  slimCollapsed: {
    type: Boolean,
    default: true,
  },
  slimWidth: {
    type: Number,
    default: 60,
  },
  sidenavWidth: {
    type: Number,
    default: 240,
  },
  backdrop: {
    type: Boolean,
    default: true,
  },
  backdropClass: String,
  backdropStyle: String,
  contentSelector: String,
  id: String,
  modeBreakpoint: Number,
  closeOnEsc: {
    type: Boolean,
    default: false,
  },
  expandOnHover: {
    type: Boolean,
    default: false,
  },
  scrollLock: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const sidenav = ref<HTMLElement | string>("sidenav");
const isMounted = ref(false);

const className = computed(() => {
  return [
    "sidenav",
    props.color && `sidenav-${props.color}`,
    props.absolute && "sidenav-absolute",
    props.relative && "sidenav-relative",
    props.light && "sidenav-light",
    props.right && "sidenav-right",
    props.slim && "sidenav-slim",
    props.slim &&
      props.slimCollapsed &&
      isSlimCollapsed.value &&
      "sidenav-slim-collapsed",
    props.classes,
  ];
});

const transitionName = computed(() => {
  return props.right ? "right-sidenav" : "sidenav";
});

const isBackdropActive = computed(() => {
  if (
    isMounted.value &&
    props.backdrop &&
    isActive.value &&
    sidenavMode.value === "over"
  ) {
    return true;
  }

  return false;
});

const getSidenavScrollheight = () => {
  if (props.relative || props.absolute) {
    const parent = (sidenav.value as HTMLElement).parentNode as HTMLElement;
    return parent.offsetHeight ?? null;
  }
  return window.innerHeight;
};

const handleHeightChange = () => {
  windowHeight.value = getSidenavScrollheight();
};

const windowHeight = ref<number | null>(null);

const sidenavMode = ref<string>(props.mode);
const toggleButton = ref<HTMLElement | null>(null);
const windowWidth = ref(window.innerWidth);
const modeTransitionOver = ref(false);

const handleModeTransitionResize = () => {
  windowWidth.value = window.innerWidth;

  if (props.modeBreakpoint && window.innerWidth < props.modeBreakpoint) {
    sidenavMode.value = "over";
    emit("update:modelValue", false);
    modeTransitionOver.value = true;
    if (toggleButton.value) {
      toggleButton.value.style.display = "unset";
    }
  } else {
    sidenavMode.value = "side";
    if (!isActive.value) {
      emit("update:modelValue", true);
    }
    modeTransitionOver.value = false;
    if (toggleButton.value) {
      toggleButton.value.style.display = "none";
    }
  }

  setContentOffset(sidenavMode.value);
};

const pageContent = ref<HTMLElement | null>(null);

const contentOffsetWidth = computed(() => {
  if (isActive.value) {
    return props.slim
      ? props.slimCollapsed
        ? props.slimWidth
        : props.slimWidth + props.sidenavWidth
      : props.sidenavWidth;
  }
  return 0;
});

const setContentOffset = (value: string) => {
  if (!pageContent.value && props.contentSelector) {
    pageContent.value = document.querySelector(props.contentSelector);
  }

  const marginProperty = props.right ? "margin-left" : "margin-right";
  const paddingProperty = props.right ? "padding-right" : "padding-left";

  if (!pageContent.value) return;

  pageContent.value.style.transition = "all 0.3s linear 0s";

  if (value === "push") {
    (pageContent.value.style as unknown as Record<string, string>)[
      marginProperty
    ] = -1 * contentOffsetWidth.value + "px";
    (pageContent.value.style as unknown as Record<string, string>)[
      paddingProperty
    ] = contentOffsetWidth.value + 20 + "px";
  } else if (value === "side") {
    (pageContent.value.style as unknown as Record<string, string>)[
      marginProperty
    ] = "0px";
    (pageContent.value.style as unknown as Record<string, string>)[
      paddingProperty
    ] = contentOffsetWidth.value + 20 + "px";
  } else if (value === "over") {
    (pageContent.value.style as unknown as Record<string, string>)[
      paddingProperty
    ] = "20px";
    (pageContent.value.style as unknown as Record<string, string>)[
      marginProperty
    ] = "0px";
  }

  setTimeout(() => {
    if (pageContent.value) {
      pageContent.value.style.transition = "all 0.3s ease 0s";
    }
  }, 300);
};

const isActive = ref(props.modelValue);

const setFocusTrapHandler = () => {
  nextTick(() => {
    calculateFocusTrap();
    on(window, "keydown", focusFirstElement);
  });
};

watch(
  () => props.modelValue,
  (cur) => {
    isActive.value = cur;

    if (isActive.value && sidenavMode.value === "over") {
      setFocusTrapHandler();
    }
    if (isActive.value && props.closeOnEsc) {
      bindHandleEscapeClick();
    }

    setContentOffset(sidenavMode.value);
  }
);

watchEffect(() => (sidenavMode.value = props.mode));

watch(
  () => props.mode,
  (cur) => {
    if (isActive.value) {
      setContentOffset(cur);
      if (cur === "over") {
        setFocusTrapHandler();
      } else if (
        cur !== "over" &&
        lastFocusableElement &&
        lastFocusableElement.value
      ) {
        removeFocusTrap();
      }
    }
  }
);

const parentsEl = (element: HTMLElement, selector: string) => {
  const parents = [];

  let ancestor = element.parentNode as HTMLElement;

  while (ancestor && ancestor.nodeType === Node.ELEMENT_NODE) {
    if (ancestor.matches(selector)) {
      parents.push(ancestor);
    }

    ancestor = ancestor.parentNode as HTMLElement;
  }

  return parents;
};

const prevEl = (element: HTMLElement, selector: string): HTMLElement[] => {
  let previous = element.previousElementSibling as HTMLElement;

  while (previous) {
    if (previous.matches(selector)) {
      return [previous];
    }

    previous = previous.previousElementSibling as HTMLElement;
  }

  return [];
};

const activeNode = ref<string | null>(null);
const setActiveNode = (id: string, node: HTMLAnchorElement) => {
  activeNode.value = id;

  const [collapse] = parentsEl(node, ".sidenav-collapse");

  if (!collapse) {
    setActiveCategory();
    return;
  }

  // Category

  const [category] = prevEl(collapse, ".sidenav-link");
  setActiveCategory(category);
};

const setActiveCategory = (el: HTMLElement | undefined = undefined) => {
  [...(sidenav.value as HTMLElement).querySelectorAll(".sidenav-menu")].forEach(
    (menu) => {
      const collapses = menu.querySelectorAll(
        ".sidenav-collapse"
      ) as NodeListOf<HTMLElement>;

      collapses.forEach((collapse) => {
        const [collapseToggler] = prevEl(collapse, ".sidenav-link");
        if (!el || collapseToggler.id !== el.id) {
          collapseToggler.classList.remove("active");
        } else {
          collapseToggler.classList.add("active");
        }
      });
    }
  );
};

provide("activeNode", activeNode);
provide("setActiveNode", setActiveNode);

const bindHandleEscapeClick = () => {
  on(document, "keydown", handleEscKey);
};

const handleEscKey = (event: KeyboardEvent) => {
  // prevent from closing sidenav when no toggle (toggleButton) is visible
  if (
    props.closeOnEsc &&
    event.key === "Escape" &&
    toggleButton.value &&
    toggleButton.value.style.display !== "none"
  ) {
    emit("update:modelValue", false);
    off(document, "keydown", handleEscKey);
  }
};

const hideSideNav = () => {
  emit("update:modelValue", false);
};

const firstFocusableElement = ref<HTMLElement | Element | null>(null);
const lastFocusableElement = ref<HTMLElement | Element | null>(null);

const calculateFocusTrap = () => {
  const focusable = Array.from(
    (sidenav.value as HTMLElement).querySelectorAll(
      'button, a, input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
  ).filter((el) => {
    return (
      !el.classList.contains("ps__thumb-x") &&
      !el.classList.contains("ps__thumb-y")
    );
  });

  if (focusable.length === 0) return;

  firstFocusableElement.value = focusable[0];
  const lastElement = focusable[focusable.length - 1];

  if (
    lastFocusableElement.value &&
    lastFocusableElement.value !== lastElement
  ) {
    off(lastFocusableElement.value as HTMLElement, "blur", focusTrap);
  }

  lastFocusableElement.value = lastElement;
  on(lastFocusableElement.value as HTMLElement, "blur", focusTrap);
};

const focusTrap = () => {
  if (!firstFocusableElement.value) return;

  (firstFocusableElement.value as HTMLElement).focus();
};

const focusFirstElement = (event: KeyboardEvent) => {
  if (isActive.value && event.key === "Tab") {
    event.preventDefault();
    focusTrap();
  }
  off(window, "keydown", focusFirstElement);
};

const removeFocusTrap = () => {
  off(lastFocusableElement.value as HTMLElement, "blur", focusTrap);
};

const isSlimCollapsed = ref(props.slimCollapsed);

watch(
  () => [props.slim, props.slimCollapsed],
  () => {
    setContentOffset(sidenavMode.value);
  }
);

const mockSpacebarJump = (event: KeyboardEvent) => {
  if (event.key !== " ") {
    return;
  }
  const jumpBy = 0.87 * window.innerHeight;
  const windowFromTop = window.scrollY;
  window.scrollTo(0, windowFromTop + jumpBy);
};

const onMouseEnter = () => {
  on(window, "keydown", mockSpacebarJump);
};

const onMouseLeave = () => {
  if (props.slim && props.expandOnHover) {
    isSlimCollapsed.value = true;
  }
  off(window, "keydown", mockSpacebarJump);
};

const onMouseOver = () => {
  if (props.slim && props.expandOnHover) {
    isSlimCollapsed.value = false;
  }
};

const swipe = (direction: string) => {
  let isOpenSwipeDirection = direction === "right" ? true : false;

  if (props.right) {
    isOpenSwipeDirection = !isOpenSwipeDirection;
  }

  if (props.slim && isSlimCollapsed.value && isOpenSwipeDirection) {
    isSlimCollapsed.value = false;
    return;
  }
  if (props.slim && !isSlimCollapsed.value && !isOpenSwipeDirection) {
    isSlimCollapsed.value = true;
  }
};

const slimCollapsed = ref(props.slimCollapsed);

provide("sidenavColor", props.color);
provide("slimCollapsed", slimCollapsed);

watch(
  () => props.slimCollapsed,
  (cur) => {
    slimCollapsed.value = cur;
  }
);

onBeforeMount(() => {
  if (props.modeBreakpoint && window.innerWidth < props.modeBreakpoint) {
    emit("update:modelValue", false);
    isActive.value = false;
  }
});

onMounted(() => {
  isMounted.value = true;

  if (props.contentSelector) {
    pageContent.value = document.querySelector(props.contentSelector);
  }

  // setting initial values for watched properties
  // in composition api setup is run on `created` cycle so following would be ran before
  // pageContent is set, thus those handlers wold not work properly
  if (props.modelValue) {
    setContentOffset(props.mode);
  }
  if (props.mode === "over") {
    setFocusTrapHandler();
  }

  if (props.closeOnEsc) {
    bindHandleEscapeClick();
  }
  // end of described handlers

  toggleButton.value = document.querySelector(`[aria-controls="#${props.id}"]`);

  if (props.dark) {
    (sidenav.value as HTMLElement).style.backgroundColor = "#2d2c2c";
  }

  windowHeight.value = getSidenavScrollheight();
  on(window, "resize", handleHeightChange);

  if (props.modeBreakpoint) {
    handleModeTransitionResize();
    on(window, "resize", handleModeTransitionResize);
  }

  on(window, "keydown", focusFirstElement);
});

onUnmounted(() => {
  off(window, "resize", handleModeTransitionResize);
  off(window, "resize", handleHeightChange);
  off(document, "keydown", handleEscKey);
});
</script>
