<template>
  <component :is="tag" v-bind="$attrs" ref="lightboxRef" :class="className">
    <slot />
  </component>
  <teleport to="body">
    <div
      ref="galleryRef"
      class="lightbox-gallery"
      :style="{
        opacity: isActive ? 1 : 0,
        pointerEvents: isActive ? 'initial' : 'none',
        visibility: isActive ? 'visible' : 'hidden',
      }"
      @mousemove="resetToolsToggler"
    >
      <div v-show="isLoading" class="lightbox-gallery-loader">
        <MDBSpinner grow color="light" />
      </div>
      <div class="lightbox-gallery-toolbar" :style="{ opacity: toolsOpacity }">
        <div class="lightbox-gallery-left-tools">
          <p class="lightbox-gallery-counter">
            {{ `${activeImgIndex + 1} / ${imgCount}` }}
          </p>
        </div>
        <div class="lightbox-gallery-right-tools">
          <button
            ref="fullscreenBtnRef"
            aria-label="Toggle fullscreen"
            :class="[
              'lightbox-gallery-fullscreen-btn',
              fullscreen && 'active',
              fontAwesome === 'pro' && 'fontawesome-pro',
            ]"
            @click="toggleFullscreen"
          ></button>
          <button
            ref="toggleBtnRef"
            :class="[
              'lightbox-gallery-zoom-btn',
              fontAwesome === 'pro' && 'fontawesome-pro',
              zoom > 1 && 'active',
            ]"
            :aria-label="zoom > 1 ? 'Zoom in' : 'Zoom out'"
            @click="toggleZoom"
          ></button>
          <button
            ref="closeBtnRef"
            aria-label="Close"
            :class="[
              'lightbox-gallery-close-btn',
              fontAwesome === 'pro' && 'fontawesome-pro',
            ]"
            @click="close"
          ></button>
        </div>
      </div>
      <div class="lightbox-gallery-content">
        <div
          v-for="(img, key) in images"
          :key="key"
          class="lightbox-gallery-image"
          :style="{
            position: 'absolute',
            top: '0px',
            left: `${img.left}%`,
            opacity: img.opacity,
            transform: `scale(${img.scale})`,
          }"
        >
          <img
            :ref="(el) => setRef(el, key)"
            :alt="img.alt || `Lightbox image ${img.id + 1}`"
            draggable="false"
            :class="[img.active ? 'active' : null, 'lightbox-image']"
            :src="img.src"
            @wheel="handleImgScroll"
            @mouseup="handleMouseup"
            @mousemove="handleMousemove"
            @mouseleave="handleMouseLeave"
            @mousedown="handleMousedown"
            @touchend="(event) => handleMouseup(event, true)"
            @touchmove="handleMousemove"
            @touchstart="handleMousedown"
            @dblclick="handleDoubleClick"
          />
        </div>
      </div>
      <div
        class="lightbox-gallery-arrow-left"
        :style="{ opacity: toolsOpacity }"
      >
        <button
          ref="arrowLeftBtnRef"
          :class="[fontAwesome === 'pro' && 'fontawesome-pro']"
          aria-label="Previous"
          @click="slide('left')"
        ></button>
      </div>
      <div
        class="lightbox-gallery-arrow-right"
        :style="{ opacity: toolsOpacity }"
      >
        <button
          ref="arrowRightBtnRef"
          :class="[fontAwesome === 'pro' && 'fontawesome-pro']"
          aria-label="Next"
          @click="slide()"
        ></button>
      </div>
      <div class="lightbox-gallery-caption-wrapper">
        <p class="lightbox-gallery-caption">
          {{ caption }}
        </p>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
export default {
  name: "MDBLightbox",
};
</script>

<script setup lang="ts">
import {
  computed,
  nextTick,
  onBeforeUpdate,
  onMounted,
  onUnmounted,
  provide,
  reactive,
  ref,
  watch,
} from "vue";
import MDBSpinner from "../../../../../src/components/free/components/MDBSpinner.vue";
import { on, off } from "../../../../../src/components/utils/MDBEventHandlers";

interface Image {
  active: boolean;
  alt: string;
  caption: string | undefined;
  disabled: string | undefined;
  id: number;
  left: number;
  opacity: number;
  ref: HTMLImageElement | null;
  refId: string;
  scale: number;
  src: string;
  thumbnail: string | undefined;
}

interface DocumentExt extends Document {
  webkitIsFullScreen?: boolean;
  mozFullScreen?: boolean;
  msFullscreenElement?: boolean;
}

const props = defineProps({
  tag: {
    type: String,
    default: "div",
  },
  zoomLevel: {
    type: [Number, String],
    default: 1,
  },
  fontAwesome: {
    type: String,
    default: "free",
  },
});

const emit = defineEmits([
  "close",
  "closed",
  "open",
  "opened",
  "slide",
  "slided",
  "zoom-in",
  "zoomed-in",
  "zoom-out",
  "zoomed-out",
]);

const lightboxRef = ref<HTMLElement | null>(null);
const galleryRef = ref<HTMLElement | null>(null);

const className = computed(() => {
  return ["lightbox"];
});

const isActive = ref(false);
const activeImgIndex = ref(-1);
const initialImagesArray = ref<Image[]>([]);
const images = ref<Image[]>([]);
const fullscreen = ref(false);

provide("initialImagesArray", initialImagesArray);

// ------------- image handling -------------

const setRef = (el: any, key: number) => {
  const imgRef = el as HTMLImageElement;
  images.value[key].ref = imgRef;
};

const caption = ref("");

watch(
  () => activeImgIndex.value,
  () => {
    nextTick(() => {
      const currentImg = getCurrentImg();

      caption.value =
        currentImg.caption ||
        currentImg.alt ||
        `Lightbox image ${currentImg.id + 1}`;
    });
  }
);

const resetImagesArrays = () => {
  initialImagesArray.value = [];
  images.value = [];
  imgCount.value = 0;
};

const queryImages = () => {
  resetImagesArrays();

  const queryImages = (lightboxRef.value as HTMLElement).querySelectorAll(
    "img"
  ) as NodeListOf<HTMLImageElement>;
  queryImages.forEach((img) => {
    addImg(img);
  });

  getImages();
};

const updateImages = () => {
  queryImages();
};

const imgCount = ref(0);
const addImg = (img: HTMLImageElement) => {
  const { lightboxThumbnail, lightboxSrc, lightboxCaption, lightboxDisabled } =
    img.dataset;

  const image = {
    id: imgCount.value,
    refId: img.id,
    left: 0,
    opacity: 1,
    scale: 0.25,
    active: false,
    thumbnail: lightboxThumbnail,
    src: (lightboxSrc || lightboxThumbnail) as string,
    alt: img.alt,
    caption: lightboxCaption,
    disabled: lightboxDisabled,
    ref: img,
  };

  if (image.disabled) {
    return;
  }

  initialImagesArray.value.push(image);
  images.value.push(image);

  imgCount.value++;
};

const setActiveImg = (imgId: number) => {
  activeImgIndex.value = imgId;
};

const setImgStyle = (img: Image, key: number) => {
  if (img.left === 0) {
    img.active = true;
    img.opacity = 1;
    img.scale = 1;
  } else {
    img.active = false;
    img.opacity = 0;
  }

  if (key === imgCount.value - 1 && imgCount.value > 1) {
    img.left = -100;
  }
};

const calculateImgSize = (img: HTMLImageElement) => {
  if (img.width >= img.height) {
    img.style.width = "100%";
    img.style.maxWidth = "100%";
    img.style.height = "auto";
    img.style.top = `${
      ((img.parentNode as HTMLElement).offsetHeight - img.height) / 2
    }px`;
    img.style.left = "0px";
  } else {
    img.style.height = "100%";
    img.style.maxHeight = "100%";
    img.style.width = "auto";
    img.style.left = `${
      ((img.parentNode as HTMLElement).offsetWidth - img.width) / 2
    }px`;
    img.style.top = "0px";
  }

  if (img.width >= (img.parentNode as HTMLElement).offsetWidth) {
    img.style.width = `${(img.parentNode as HTMLElement).offsetWidth}px`;
    img.style.height = "auto";
    img.style.left = "0px";
    img.style.top = `${
      ((img.parentNode as HTMLElement).offsetHeight - img.height) / 2
    }px`;
  }
  if (img.height >= (img.parentNode as HTMLElement).offsetHeight) {
    img.style.height = `${(img.parentNode as HTMLElement).offsetHeight}px`;
    img.style.width = "auto";
    img.style.top = "0px";
    img.style.left = `${
      ((img.parentNode as HTMLElement).offsetWidth - img.width) / 2
    }px`;
  }
};

const getImages = () => {
  images.value = [...initialImagesArray.value];
};

const resetImagesValues = () => {
  images.value = images.value.map((img) => {
    return {
      ...img,
      left: 0,
      opacity: 0,
      active: false,
    };
  });
};

const getCurrentImg = () => {
  return images.value.filter((img) => img.left === 0)[0];
};

const sortImages = () => {
  for (let i = 0; i < activeImgIndex.value; i++) {
    images.value.push(images.value.shift() as Image);
  }
};

const resetImgLeftPosition = () => {
  images.value = images.value.map((img, i) => {
    return {
      ...img,
      left: 0 + 100 * i,
    };
  });
};

const resetImagesForClosing = () => {
  images.value = images.value.map((img) => ({
    ...img,
    opacity: 0,
    scale: 0.25,
  }));
};

provide("addImg", addImg);

// ------------- loader methods -------------
const isLoading = ref(false);

// ------------- tools methods -------------
const toolsOpacity = ref(1);
const toolsToggleTimer = ref(0);

const resetToolsToggler = () => {
  toolsOpacity.value = 1;
  clearTimeout(toolsToggleTimer.value);
  setToolsToggleTimout();
};

const setToolsToggleTimout = () => {
  toolsToggleTimer.value = setTimeout(() => {
    toolsOpacity.value = 0;

    clearTimeout(toolsToggleTimer.value);
  }, 4000);
};
// ------------- scrollbar methods -------------
const disableScroll = () => {
  document.body.classList.add("disabled-scroll");

  if (
    document.documentElement.scrollHeight >
    document.documentElement.clientHeight
  ) {
    document.body.classList.add("replace-scrollbar");
  }
};

const enableScroll = () => {
  setTimeout(() => {
    document.body.classList.remove("disabled-scroll");
    document.body.classList.remove("replace-scrollbar");
  }, 300);
};

// ------------- gallery methods -------------
const toggleLightbox = (itemId = 0) => {
  if (itemId === -1 || isActive.value) {
    close();
  } else {
    open(itemId);
  }
};

const toggleFullscreen = () => {
  if (!fullscreen.value) {
    if (galleryRef.value?.requestFullscreen) {
      galleryRef.value.requestFullscreen();
    }

    fullscreen.value = true;
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }

    fullscreen.value = false;
  }
};
provide("toggleLightbox", toggleLightbox);

const open = (itemId = 0) => {
  emit("open");
  getImages();
  setActiveImg(itemId);
  sortImages();
  resetImgLeftPosition();
  isActive.value = true;

  nextTick(() => {
    images.value.forEach((img, key) => {
      setImgStyle(img, key);
      img.ref && calculateImgSize(img.ref);
    });

    setTimeout(() => {
      images.value.forEach((img) => {
        if (img.left === 0) {
          img.scale = 1;
        }
      });
    }, 50);

    disableScroll();
    setToolsToggleTimout();
    addEvents();
    emit("opened");

    setFocusOn();
  });
};

const close = () => {
  if (isActive.value) {
    emit("close");
    if (fullscreen.value) {
      toggleFullscreen();
    }
    enableScroll();
    removeEvents();

    // reset opacity and scale to begin the animation
    resetImagesForClosing();

    isActive.value = false;
    activeImgIndex.value = -1;

    // remove zoom if closed
    restoreDefaultZoom();
    resetImagesValues();

    emit("closed");

    setFocusOn();
  }
};

// ------------- focus trap -------------

const fullscreenBtnRef = ref<HTMLElement | null>(null);
const toggleBtnRef = ref<HTMLElement | null>(null);
const closeBtnRef = ref<HTMLElement | null>(null);
const arrowLeftBtnRef = ref<HTMLElement | null>(null);
const arrowRightBtnRef = ref<HTMLElement | null>(null);

const focusTrapCount = ref(0);

const focusableButtons = [
  fullscreenBtnRef,
  toggleBtnRef,
  closeBtnRef,
  arrowLeftBtnRef,
  arrowRightBtnRef,
];

const tabCount = (e: KeyboardEvent) => {
  if (e.key === "Tab") {
    e.preventDefault();

    focusableButtons[focusTrapCount.value].value?.focus();

    if (focusTrapCount.value < focusableButtons.length - 1) {
      focusTrapCount.value++;
    } else {
      focusTrapCount.value = 0;
    }
  }
};

const setFocusOn = () => {
  if (isActive.value) {
    setTimeout(() => {
      window.addEventListener("keydown", tabCount);
    }, 100);
  } else if (!isActive.value) {
    setTimeout(() => {
      focusTrapCount.value = 0;
      window.removeEventListener("keydown", tabCount);
    }, 100);
  }
};

// ------------- image sliding -------------
const isAnimating = ref(false);
const animationStart = () => {
  isAnimating.value = true;
  setTimeout(() => {
    isAnimating.value = false;
  }, 400);
};

const slideHorizontally = (direction: number) => {
  images.value.forEach((img) => {
    let newPositionLeft: number;

    if (direction === 1) {
      newPositionLeft = img.left - 100;
      if (newPositionLeft < -100) newPositionLeft = (imgCount.value - 2) * 100;
    } else {
      newPositionLeft = img.left + 100;
      if (newPositionLeft === (imgCount.value - 1) * 100)
        newPositionLeft = -100;
    }

    if (newPositionLeft === 0) {
      img.opacity = 1;
      img.scale = 1;
      img.active = true;
      img.ref && calculateImgSize(img.ref);
    } else {
      img.opacity = 0;
      img.scale = 0.25;
      img.active = false;
    }

    img.left = newPositionLeft;
  });
};

const slideToTarget = (target: number) => {
  if (target === 0 && activeImgIndex.value === 0) {
    return;
  }
  if (
    target === imgCount.value - 1 &&
    activeImgIndex.value === imgCount.value - 1
  ) {
    return;
  }

  isLoading.value = true;

  images.value.forEach((img) => {
    img.opacity = 0;
    img.scale = 0.25;
  });
  resetImagesValues();

  setTimeout(() => {
    getImages();
    setActiveImg(target === 0 ? 0 : imgCount.value - 1);
    sortImages();
    resetImgLeftPosition();

    nextTick(() => {
      const imgRef = getCurrentImg().ref;
      imgRef && calculateImgSize(imgRef);

      images.value.forEach((img, key) => {
        setImgStyle(img, key);
      });
      isLoading.value = false;
    });
  }, 400);
};

const slide = (direction = "right") => {
  if (isAnimating.value || imgCount.value <= 1) {
    return;
  }

  emit("slide");

  animationStart();
  resetDoubleTap();

  let change: number | undefined = undefined;

  switch (direction) {
    case "right":
      change = 1;
      break;
    case "left":
      change = -1;
      break;
    case "first":
      change = 0;
      break;
    case "last":
      change = imgCount.value - 1;
      break;

    default:
      break;
  }

  if (change && Math.abs(change) === 1) {
    slideHorizontally(change);
    const next =
      activeImgIndex.value + change < 0
        ? imgCount.value - 1
        : activeImgIndex.value + change > imgCount.value - 1
        ? 0
        : activeImgIndex.value + change;

    setActiveImg(next);
  } else {
    change && slideToTarget(change);
  }

  // remove zoom if slide
  restoreDefaultZoom();

  emit("slided");
};

// ------------- gestures -------------
const originalPosition = reactive({
  x: 0,
  y: 0,
});

const position = reactive({
  x: 0,
  y: 0,
});

const mousedownPosition = reactive({
  x: 0,
  y: 0,
});

const doubleTapTimer = ref(0);
const tapTime = ref(0);
const tapCounter = ref(0);

const checkDoubleTap = (e: MouseEvent | TouchEvent) => {
  clearTimeout(doubleTapTimer.value);
  const currentTime = new Date().getTime();
  const tapLength = currentTime - tapTime.value;

  if (tapCounter.value > 0 && tapLength < 500) {
    handleDoubleClick(e);
    doubleTapTimer.value = setTimeout(() => {
      tapTime.value = new Date().getTime();
      tapCounter.value = 0;
    }, 300);
  } else {
    tapCounter.value++;
    tapTime.value = new Date().getTime();
  }
};

const resetDoubleTap = () => {
  tapTime.value = 0;
  tapCounter.value = 0;
  clearTimeout(doubleTapTimer.value);
};

const handleDoubleClick = (e: MouseEvent | TouchEvent) => {
  if (multitouch) {
    return;
  }
  if (e instanceof MouseEvent) {
    setNewPositionOnZoomIn(e);
  }
  if (zoom.value !== 1) {
    restoreDefaultZoom();
  } else {
    zoomIn();
  }
};

const mousedown = ref(false);
let touchZoomPosition: TouchList | [];
let multitouch = false;

const handleMousedown = (event: MouseEvent | TouchEvent) => {
  const touch = event instanceof MouseEvent ? undefined : event.touches;

  if (touch && touch?.length > 1) {
    multitouch = true;
    touchZoomPosition = touch;
  }

  const x = touch ? touch[0].clientX : (event as MouseEvent).clientX;
  const y = touch ? touch[0].clientY : (event as MouseEvent).clientY;

  const { ref: currentImg } = getCurrentImg();

  originalPosition.x = (currentImg && parseFloat(currentImg.style.left)) || 0;
  originalPosition.y = (currentImg && parseFloat(currentImg.style.top)) || 0;
  position.x = originalPosition.x;
  position.y = originalPosition.y;
  mousedownPosition.x = x * (1 / zoom.value) - position.x;
  mousedownPosition.y = y * (1 / zoom.value) - position.y;
  mousedown.value = true;
};

const handleMouseLeave = () => {
  if (!mousedown.value) return;
  mousedown.value = false;
};

const handleMousemove = (e: MouseEvent | TouchEvent) => {
  if (!mousedown.value) return;

  const touch = e instanceof MouseEvent ? undefined : e.touches;
  const x = touch ? touch[0].clientX : (e as MouseEvent).clientX;
  const y = touch ? touch[0].clientY : (e as MouseEvent).clientY;

  if (touch) resetToolsToggler();
  const { ref: currentImg } = getCurrentImg();

  if (!multitouch) {
    if (zoom.value !== 1) {
      position.x = x * (1 / zoom.value) - mousedownPosition.x;
      position.y = y * (1 / zoom.value) - mousedownPosition.y;

      if (currentImg) {
        currentImg.style.left = `${position.x}px`;
        currentImg.style.top = `${position.y}px`;
      }
    } else {
      if (images.value.length <= 1) return;
      position.x = x * (1 / zoom.value) - mousedownPosition.x;
      currentImg && (currentImg.style.left = `${position.x}px`);
    }
  }
};

const handleMouseup = (
  event: MouseEvent | TouchEvent,
  touch: boolean | undefined = undefined
) => {
  if (!mousedown.value) return;

  const moveX = position.x - originalPosition.x;
  mousedown.value = false;

  if (moveX !== 0) {
    moveImg(moveX);
  }

  if (touch) {
    checkDoubleTap(event);
  }

  if (multitouch) {
    if ((event as TouchEvent).targetTouches.length === 0) {
      multitouch = false;
      touchZoomPosition = [];
    }
  }
};

const moveImg = (movement: number) => {
  if (multitouch) {
    return;
  }
  if (zoom.value !== 1 || imgCount.value <= 1) {
    return;
  }

  if (movement > 0) {
    if (isRTL) {
      slide();
    } else {
      slide("left");
    }
  } else if (movement < 0) {
    if (isRTL) {
      slide("left");
    } else {
      slide();
    }
  }
};

const handleWindowResize = () => {
  images.value.forEach((img) => {
    img.ref && calculateImgSize(img.ref);
  });
};

const handleFullScreenChange = () => {
  const isFullscreenEnabled =
    (document as DocumentExt).webkitIsFullScreen ||
    (document as DocumentExt).mozFullScreen ||
    (document as DocumentExt).msFullscreenElement;
  if (isFullscreenEnabled === undefined) {
    fullscreen.value = false;
  }
};

// ------------- zoom -------------
const zoom = ref(1);
const zoomTimer = ref<ReturnType<typeof setInterval>>();

const toggleZoom = () => {
  if (zoom.value !== 1) {
    restoreDefaultZoom();
  } else {
    zoomIn();
  }
};

const zoomIn = () => {
  if (zoom.value >= 3) return;
  emit("zoom-in");
  const zoomValue =
    typeof props.zoomLevel === "string"
      ? parseFloat(props.zoomLevel)
      : props.zoomLevel;
  zoom.value += zoomValue;
  getCurrentImg().scale = zoom.value;

  emit("zoomed-in");
};

const zoomOut = () => {
  if (zoom.value <= 1) return;
  emit("zoom-out");
  const zoomValue =
    typeof props.zoomLevel === "string"
      ? parseFloat(props.zoomLevel)
      : props.zoomLevel;
  zoom.value -= zoomValue;
  getCurrentImg().scale = zoom.value;

  emit("zoomed-out");
  if (zoom.value === 1) {
    restoreDefaultPosition();
  }
};

const restoreDefaultZoom = () => {
  if (zoom.value !== 1) {
    emit("zoom-out");
    zoom.value = 1;
    getCurrentImg().scale = 1;
    restoreDefaultPosition();
    emit("zoomed-out");
  }
};

const handleImgScroll = (e: WheelEvent) => {
  if (e.deltaY > 0) {
    zoomOut();
  } else {
    if (zoom.value >= 3) return;
    setNewPositionOnZoomIn(e);
    zoomIn();
  }
};

const setNewPositionOnZoomIn = (e: MouseEvent) => {
  clearTimeout(zoomTimer.value);
  const positionX = window.innerWidth / 2 - e.offsetX - 50;
  const positionY = window.innerHeight / 2 - e.offsetY - 50;

  const currentImg = getCurrentImg();

  if (!currentImg.ref) {
    return;
  }

  currentImg.ref.style.transition = "all 0.5s ease-out";
  currentImg.ref.style.left = `${positionX}px`;
  currentImg.ref.style.top = `${positionY}px`;

  zoomTimer.value = setTimeout(() => {
    if (currentImg.ref) {
      currentImg.ref.style.transition = "none";
    }
  }, 500);
};

const restoreDefaultPosition = () => {
  clearTimeout(zoomTimer.value);
  const currentImg = getCurrentImg().ref;

  if (!currentImg) {
    return;
  }

  currentImg.style.transition = "all 0.5s ease-out";
  currentImg.style.top = "0px";
  currentImg.style.left = "0px";

  calculateImgSize(currentImg);

  setTimeout(() => {
    currentImg.style.transition = "none";
  }, 500);
};

const calculateTouchZoom = (event: TouchEvent) => {
  const initialDistance = Math.hypot(
    touchZoomPosition[1].pageX - touchZoomPosition[0].pageX,
    touchZoomPosition[1].pageY - touchZoomPosition[0].pageY
  );
  const finalDistance = Math.hypot(
    event.touches[1].pageX - event.touches[0].pageX,
    event.touches[1].pageY - event.touches[0].pageY
  );
  const distanceChange = Math.abs(initialDistance - finalDistance);
  const screenWidth = event.view?.screen.width;

  if (screenWidth && distanceChange > screenWidth * 0.03) {
    if (initialDistance <= finalDistance) {
      zoomIn();
    } else {
      zoomOut();
    }
    touchZoomPosition = event.touches;
  }
};

const onWindowTouchstart = (event: TouchEvent) => {
  if (event.touches.length > 1) {
    multitouch = true;
    touchZoomPosition = event.touches;
  }
};

const onWindowTouchmove = (event: TouchEvent) => {
  if (
    multitouch &&
    event.type === "touchmove" &&
    event.targetTouches.length > 1
  ) {
    event.preventDefault();
    calculateTouchZoom(event);
  }
};

// ------------- navigation -------------
const isRTL = document.documentElement.dir === "rtl";

const handleBackdropClick = (e: MouseEvent) => {
  resetToolsToggler();

  if ((e.target as HTMLElement).tagName !== "DIV") return;
  close();
};

const handleKeyup = (e: KeyboardEvent) => {
  if (!isActive.value) {
    return;
  }
  e.preventDefault();

  resetToolsToggler();
  switch (e.keyCode) {
    case 39:
      if (isRTL) {
        slide("left");
      } else {
        slide();
      }

      break;
    case 37:
      if (isRTL) {
        slide();
      } else {
        slide("left");
      }

      break;
    case 27:
      close();
      break;
    case 36:
      slide("first");
      break;
    case 35:
      slide("last");
      break;
    case 38:
      zoomIn();
      break;
    case 40:
      zoomOut();
      break;
    default:
      break;
  }
};

// ------------- events -------------
const addEvents = () => {
  on(galleryRef.value as HTMLElement, "click", handleBackdropClick);
  on(window, "keyup", handleKeyup);
  on(window, "resize", handleWindowResize);
  on(window, "orientationchange", handleWindowResize);
  on(window, "fullscreenchange", handleFullScreenChange);
  on(window, "touchstart", onWindowTouchstart);
};

const removeEvents = () => {
  off(galleryRef.value as HTMLElement, "click", handleBackdropClick);
  off(window, "keyup", handleKeyup);
  off(window, "resize", handleWindowResize);
  off(window, "orientationchange", handleWindowResize);
  off(window, "fullscreenchange", handleFullScreenChange);
  off(window, "touchstart", onWindowTouchstart);
};

// ------------- lifecycle -------------
onMounted(() => {
  addEvents();
  queryImages();
  document.addEventListener("touchmove", onWindowTouchmove, { passive: false });
});

onUnmounted(() => {
  removeEvents();
  document.removeEventListener("touchmove", onWindowTouchmove);
});

onBeforeUpdate(() => {
  images.value.forEach((img) => {
    img.ref = null;
  });
});

defineExpose({
  updateImages,
  open,
  slide,
  zoomIn,
  zoomOut,
  toggleFullscreen,
  close,
  addImg,
  resetImagesArrays,
  getCurrentImg,
  activeImgIndex,
  initialImagesArray,
  lightboxRef,
});
</script>
