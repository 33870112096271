<template>
  <component
    :is="tag"
    :key="datatableKey"
    :class="className"
    :style="{ maxWidth: width }"
  >
    <div
      class="datatable-inner table-responsive"
      style="overflow: auto; position: relative"
      :key="resizeKey"
      ref="datatableInnerRef"
    >
      <MDBScrollbar
        :width="width"
        :height="height"
        :wheelPropagation="true"
        style="background-color: inherit"
      >
        <table class="table datatable-table" :id="id">
          <thead v-if="data.columns" class="datatable-header">
            <tr>
              <th
                v-if="selectable"
                scope="col"
                :class="fixedHeader && 'fixed-cell'"
              >
                <MDBCheckbox
                  v-if="multi"
                  :disabled="!hasRows"
                  v-model="allFilteredRowsSelectedCheckbox"
                  @click.stop="toggleSelectAll"
                  wrapperClass="d-flex align-items-center mb-0"
                />
              </th>
              <th
                v-for="(col, colKey) in data.columns"
                :key="'col-' + colKey"
                scope="col"
                :style="{
                  cursor: col.sort !== false ? 'pointer' : 'default',
                  minWidth: col.width + 'px',
                  maxWidth: col.width + 'px',
                  left: col.fixed && !col.right ? col.left + 'px' : '',
                  right: col.fixed && col.right ? 0 : '',
                }"
                :class="(fixedHeader || col.fixed) && 'fixed-cell'"
                @click="col.sort !== false && sortAndFilter(col.field)"
              >
                <i
                  v-if="col.sort !== false"
                  class="datatable-sort-icon fas fa-arrow-up"
                  :class="orderBy && orderKey === col.field && 'active'"
                  :style="{
                    transform:
                      orderBy === 'desc' && orderKey === col.field
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                  }"
                ></i>
                {{ col.label }}
              </th>
            </tr>
          </thead>
          <tbody
            v-if="(data.rows && data.rows.length > 0) || loading"
            class="datatable-body"
          >
            <tr
              v-for="(row, rowKey) in data.rows?.slice(
                pageKey * rowsPerPage,
                pageKey * rowsPerPage + rowsPerPage
              )"
              :key="'row-' + row.mdbIndex"
              :data-mdb-index="row.mdbIndex"
              :class="row.selected && 'active'"
              scope="row"
              @click="handleRowClick(row.mdbIndex)"
            >
              <td v-if="selectable">
                <MDBCheckbox
                  v-model="row.selected"
                  :data-mdb-row-index="row.mdbIndex"
                  @click.stop
                  @change="handleCheckboxChange(row.mdbIndex, row.selected)"
                />
              </td>
              <td
                v-for="(col, colKey) in data.columns"
                :key="'cell-' + colKey"
                :style="[
                  row.formats && row.formats[col.field],
                  {
                    minWidth: col.width + 'px',
                    maxWidth: col.width + 'px',
                    left: col.fixed && !col.right ? col.left + 'px' : false,
                    right: col.fixed && col.right ? 0 : false,
                  },
                ]"
                :class="col.fixed && 'fixed-cell'"
                :contenteditable="edit ? true : undefined"
                @blur="handleCellBlur($event, rowKey, col.field)"
                v-html="
                  row[col.field]
                    ? row[col.field]
                    : row[col.field] === 0
                    ? 0
                    : defaultValue
                "
              ></td>
            </tr>
          </tbody>
          <tbody v-else class="datatable-body">
            <tr>
              <td>
                {{ noFoundMessage }}
              </td>
            </tr>
          </tbody>
        </table>
      </MDBScrollbar>
    </div>

    <div v-if="loading" class="datatable-loader bg-light">
      <span class="datatable-loader-inner"
        ><span class="datatable-progress" :class="loaderClass"></span
      ></span>
    </div>
    <p v-if="loading" class="text-center text-muted my-4">
      {{ loadingMessage }}
    </p>

    <div v-if="pagination" class="datatable-pagination">
      <div class="datatable-select-wrapper">
        <p class="datatable-select-text">{{ rowsText }}</p>
        <MDBSelect
          v-model:options="selectOptions"
          v-model:selected="rowsPerPage"
        />
      </div>
      <div class="datatable-pagination-nav">
        {{ hasRows ? `${firstRowIndex} - ${lastRowIndex}` : 0 }}
        {{ ofPaginationText }}
        {{ data.rows ? data.rows.length : "" }}
      </div>
      <div class="datatable-pagination-buttons">
        <MDBBtn
          v-if="fullPagination"
          :ripple="false"
          color="link"
          class="datatable-pagination-button datatable-pagination-start"
          :disabled="pageKey === 0 ? true : null"
          @click="
            () => {
              pageKey = 0;
              $nextTick(() => $emit('render', data));
            }
          "
        >
          <i class="fa fa-angle-double-left"></i>
        </MDBBtn>
        <MDBBtn
          :ripple="false"
          color="link"
          class="datatable-pagination-button datatable-pagination-left"
          :disabled="pageKey === 0 ? true : null"
          @click="
            () => {
              pageKey--;
              $nextTick(() => $emit('render', data));
            }
          "
        >
          <i class="fa fa-chevron-left"></i>
        </MDBBtn>
        <MDBBtn
          :ripple="false"
          color="link"
          class="datatable-pagination-button datatable-pagination-right"
          :disabled="pageKey === pages - 1 || pages === 0 ? true : null"
          @click="
            () => {
              pageKey++;
              $nextTick(() => $emit('render', data));
            }
          "
        >
          <i class="fa fa-chevron-right"></i>
        </MDBBtn>
        <MDBBtn
          v-if="fullPagination"
          :ripple="false"
          color="link"
          class="datatable-pagination-button datatable-pagination-start"
          :disabled="pageKey === pages - 1 || pages === 0 ? true : null"
          @click="
            () => {
              pageKey = pages - 1;
              $nextTick(() => $emit('render', data));
            }
          "
        >
          <i class="fa fa-angle-double-right"></i>
        </MDBBtn>
      </div>
    </div>
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBDatatable",
};
</script>

<script setup lang="ts">
import {
  computed,
  ref,
  onMounted,
  watch,
  nextTick,
  useSlots,
  VNode,
  PropType,
  onUnmounted,
} from "vue";
import MDBSelect from "../../../../src/components/pro/forms/MDBSelect.vue";
import MDBCheckbox from "../../../../src/components/free/forms/MDBCheckbox.vue";
import MDBBtn from "../../../../src/components/free/components/MDBBtn.vue";
import MDBScrollbar from "../../../../src/components/pro/methods/MDBScrollbar.vue";

interface DatatableItem {
  [props: string]: any;
}

interface Data {
  rows?: DatatableItem[];
  columns?: DatatableItem[];
}

const props = defineProps({
  bordered: Boolean,
  borderless: Boolean,
  borderColor: String,
  clickableRows: Boolean,
  color: String,
  dark: Boolean,
  defaultValue: {
    type: String,
    default: "-",
  },
  dataset: {
    type: Object as PropType<{
      columns?: string[] | { label: string; field: string; sort?: boolean }[];
      rows?: string[] | { [props: string]: string | number }[];
    }>,
    default() {
      return {
        columns: [],
        rows: [],
      };
    },
  },
  edit: Boolean,
  entries: {
    type: Number,
    default: 10,
  },
  entriesOptions: {
    type: Array as PropType<(string | number)[]>,
    default: () => [10, 25, 50, 200],
  },
  fixedHeader: Boolean,
  fullPagination: Boolean,
  hover: Boolean,
  id: String,
  loaderClass: {
    type: String,
    default: "bg-primary",
  },
  loading: Boolean,
  loadingMessage: {
    type: String,
    default: "Loading results...",
  },
  maxHeight: [Number, String],
  maxWidth: {
    type: [Number, String],
    default: "100%",
  },
  multi: Boolean,
  noFoundMessage: {
    type: String,
    default: "No matching results found",
  },
  ofPaginationText: {
    type: String,
    default: "of",
  },
  pagination: {
    type: Boolean,
    default: true,
  },
  rowsText: {
    type: String,
    default: "Rows per page:",
  },
  search: String,
  searchColumns: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  selectable: Boolean,
  sm: Boolean,
  sortField: String,
  sortOrder: String,
  striped: Boolean,
  tag: {
    type: String,
    default: "div",
  },
  searchCaseSensitive: Boolean,
  disableSortToDefault: Boolean,
});

const emit = defineEmits([
  "render",
  "selected-rows",
  "selected-indexes",
  "all-selected",
  "all-filtered-rows-selected",
  "row-click",
  "update",
]);

const slots = useSlots();

// Defaults
const className = computed(() => [
  "datatable",
  props.color,
  props.bordered && "datatable-bordered",
  props.borderColor && `border-${props.borderColor}`,
  props.borderless && "datatable-borderless",
  props.clickableRows && "datatable-clickable-rows",
  props.dark && "datatable-dark",
  props.hover && "datatable-hover",
  props.loading && "datatable-loading",
  props.sm && "datatable-sm",
  props.striped && "datatable-striped",
]);
const height = computed(() =>
  typeof props.maxHeight === "number" ? props.maxHeight + "px" : props.maxHeight
);
const width = computed(() =>
  typeof props.maxWidth === "number" ? props.maxWidth + "px" : props.maxWidth
);
const data = ref<Data>({});
const rowsPerPage = ref(props.entries);
const pageKey = ref(0);
const pages = computed(() =>
  data.value.rows ? Math.ceil(data.value.rows.length / rowsPerPage.value) : 1
);
const firstRowIndex = computed(() =>
  data.value.rows ? pageKey.value * rowsPerPage.value + 1 : 1
);
const lastRowIndex = computed(() =>
  data.value.rows
    ? pageKey.value === pages.value - 1
      ? data.value.rows.length
      : pageKey.value * rowsPerPage.value + rowsPerPage.value
    : rowsPerPage.value
);
const hasRows = computed(() =>
  data.value.rows ? data.value.rows.length > 0 : false
);
const allRows = computed(() => data.value.rows && data.value.rows.length);

const selectOptions = ref(
  props.entriesOptions.map((entry) => {
    return {
      text: entry,
      value: typeof entry === "string" ? allRows : entry,
      selected: entry === rowsPerPage.value,
    };
  })
);
const datatableKey = ref(0);
const defaultData = ref<Data>({});

const resizeKey = ref(0);
const datatableInnerRef = ref<HTMLElement | null>(null);

const handleResize = () => {
  // updating resize key will force the component to re-render and will make the borders of the fixed columns appear correctly
  resizeKey.value++;
};

const hasFixedData = () => {
  const hasFixedData =
    data.value.columns && data.value.columns.some((col) => col.fixed);
  const hasFixedCell =
    datatableInnerRef.value?.querySelector(".fixed-cell") !== undefined;

  return hasFixedData || hasFixedCell;
};

// Getting data
onMounted(() => {
  if (slots.default && slots.default()[0].type === "table") {
    getDataFromSlot(slots.default()[0]);
  } else {
    getDataFromProps();
  }

  if (hasFixedData()) {
    window.addEventListener("resize", handleResize);
  }
});
if (slots.default) {
  watch(
    () => slots.default?.(),
    () => {
      if (
        slots.default &&
        slots.default().length > 0 &&
        slots.default()[0].type === "table"
      ) {
        getDataFromSlot(slots.default()[0]);
      }
    }
  );
} else {
  watch(
    () => props.dataset,
    () => {
      getDataFromProps();
      sort();
      filter();
    },
    { deep: true }
  );
}
watch(
  () => rowsPerPage.value,
  () => {
    pageKey.value = 0;
    datatableKey.value++;
    nextTick(() => emit("render", data.value));
  }
);

// Setting data
const setData = (columns: DatatableItem[], rows: DatatableItem[]) => {
  data.value.columns = columns;
  data.value.rows = rows;

  setDefaultData(columns, rows);
  nextTick(() => emit("render", data.value));
};
const setDefaultData = (columns: DatatableItem[], rows: DatatableItem[]) => {
  defaultData.value.columns = [...columns];
  defaultData.value.rows = [...rows];
};
const getGeneratedColumns = () => {
  const columns = props.dataset.columns as DatatableItem[];

  if (columns && columns[0].field) {
    return [...columns];
  } else {
    return columns.map((th: DatatableItem) => {
      return {
        label: th,
        field: th.toLowerCase(),
      };
    });
  }
};
const getGeneratedRows = (columns: DatatableItem[]) => {
  let rows: DatatableItem[] = [];

  const datasetRows = props.dataset.rows as DatatableItem[];
  const index = columns[0].field as string;
  const firstCell = datasetRows[0][index];

  if (firstCell || firstCell === 0) {
    rows = datasetRows.map((row: DatatableItem, key: number) => ({
      ...row,
      mdbIndex: key,
      selected: false,
    }));
  } else {
    const rowsArr = datasetRows.map((tr: DatatableItem) => tr);
    rowsArr.forEach((row: DatatableItem, key: number) => {
      rows.push({});
      row.forEach((td: DatatableItem, tdKey: number) => {
        rows[key][columns[tdKey].field] = td;
        rows[key].mdbIndex = key;
        rows[key].selected = false;
      });
    });
  }

  return rows;
};
const setColMarginsAndFormats = (
  columns: DatatableItem[],
  formattedColumns: DatatableItem[]
) => {
  let colMarginLeft = -columns[0].width || 0;
  const colLeftMargins = columns.map((col) => {
    colMarginLeft += col.fixed ? col.width || 0 : 0;
    return colMarginLeft;
  });
  columns.forEach((col, key) => {
    if (col.fixed && col.fixed === "right") {
      col.right = true;
    }

    if ("format" in col) {
      formattedColumns.push({
        field: col.field,
        rules: col.format.value ? col.format.value : col.format,
      });
    }

    col.left = colLeftMargins[key];
  });
};
const formatCells = (
  rows: DatatableItem[],
  formattedColumns: DatatableItem[]
) => {
  rows.forEach((row, key) => {
    row.formats = {};
    formattedColumns.forEach((col) => {
      row.formats[col.field] = col.rules[key];
    });
  });
};

const createStringWithProperties = (el: VNode): string => {
  let props = "";
  if (el.props) {
    Object.keys(el.props).forEach((key) => {
      props += `${key}="${el.props && el.props[key]}"`;
    });
  }
  return props;
};

const createHTMLElementStringFromSlots = (data: VNode[]): string => {
  const content: string[] = [];
  data.forEach((el) => {
    if (el.children !== " ") {
      const props = createStringWithProperties(el);
      if (typeof el.type !== "symbol") {
        content.push(`<${el.type} ${props}>`);
      }
    }
    if (el.children) {
      if (typeof el.children === "string") {
        content.push(`${el.children}`);
      } else {
        const childrenElements = createHTMLElementStringFromSlots(
          el.children as VNode[]
        );
        content.push(childrenElements);
      }
    }

    if (el.children !== " ") {
      if (typeof el.type !== "symbol") {
        content.push(`</${el.type}>`);
      }
    }
  });

  return content.join("");
};

const getDataFromSlot = (slot: VNode) => {
  const slotChildrenColumn = (slot as any).children[0].children[0]
    .children as VNode[];

  const columns: DatatableItem[] = slotChildrenColumn.map((th: VNode) => {
    return {
      label: th.children,
      field: (th.children as string).toLowerCase(),
      sort: th.props && th.props["data-mdb-sort"] === "false" ? false : true,
    };
  });
  const rows: DatatableItem[] = [];
  const rowsObj: DatatableItem[] = (
    (slot as any).children[1].children as VNode[]
  ).map((tr: VNode) => tr.children as DatatableItem);

  rowsObj.forEach((row: DatatableItem, key) => {
    rows.push({});
    row.forEach((td: DatatableItem, tdKey: number) => {
      if (typeof td.children === "object") {
        rows[key][columns[tdKey].field] = createHTMLElementStringFromSlots(
          td.children
        );
      } else {
        rows[key][columns[tdKey].field] = td.children;
      }
      rows[key].mdbIndex = key;
      rows[key].selected = false;
    });
  });

  setData(columns, rows);
};
const getDataFromProps = () => {
  let columns: DatatableItem[] = [];
  let rows: DatatableItem[] = [];

  if (props.dataset.columns && props.dataset.columns.length > 0) {
    columns = getGeneratedColumns();
  }
  if (props.dataset.rows && props.dataset.rows.length > 0) {
    rows = getGeneratedRows(columns);
  }

  // Formatting
  const formattedColumns: DatatableItem[] = [];
  setColMarginsAndFormats(columns, formattedColumns);
  if (formattedColumns.length > 0) {
    formatCells(rows, formattedColumns);
  }

  setData(columns, rows);
};

const setActivePage = (value: number) => {
  if (value < pages.value) {
    pageKey.value = value;
    emit("render", data.value);
  }
};

// Sort
const orderBy = ref(props.sortOrder || null);
const orderKey = ref(props.sortField || null);
const setOrderData = (order: string | null, key: string | null) => {
  orderBy.value = order;
  orderKey.value = key;
};
const sortAsc = () => {
  data?.value?.rows?.sort((a, b) =>
    a[orderKey.value as string] > b[orderKey.value as string]
      ? 1
      : b[orderKey.value as string] > a[orderKey.value as string]
      ? -1
      : 0
  );
};
const sortDesc = () => {
  data?.value?.rows?.sort((a, b) =>
    a[orderKey.value as string] < b[orderKey.value as string]
      ? 1
      : b[orderKey.value as string] < a[orderKey.value as string]
      ? -1
      : 0
  );
};
const sortAndFilter = (key: string) => {
  if (
    orderBy.value === null ||
    orderKey.value !== key ||
    (props.disableSortToDefault && orderBy.value === "desc")
  ) {
    setOrderData("asc", key);
    sortAsc();
  } else if (orderBy.value === "asc" && orderKey.value === key) {
    setOrderData("desc", key);
    sortDesc();
  } else {
    setOrderData(null, null);

    if (defaultData.value.rows) {
      data.value.rows = [...defaultData.value.rows];
    } else {
      // Handle the case when defaultData.value.rows is undefined
      data.value.rows = [];
    }

    if (search.value) {
      filter();
    }
  }

  nextTick(() => emit("render", data.value));
};
const sort = () => {
  if (orderBy.value === "asc") {
    sortAsc();
  } else if (orderBy.value === "desc") {
    sortDesc();
  }
  nextTick(() => emit("render", data.value));
};
onMounted(() => {
  if (orderKey.value) {
    sort();
  }
});

// Search
const search = ref("");
const searchColumns = ref(props.searchColumns as string[]);
const filter = () => {
  if (searchColumns.value.length > 0) {
    data.value.rows = defaultData.value.rows?.filter((row) =>
      searchColumns.value
        .map((column) => {
          const td = row[column]?.toString().replace(/(<([^>]+)>)/gi, "");

          return props.searchCaseSensitive
            ? td?.toString().includes(search.value)
            : td?.toString().toLowerCase().includes(search.value);
        })
        .some((value) => value === true)
    );
  } else {
    data.value.rows = defaultData.value.rows?.filter((row) =>
      data.value.columns
        ?.map((column) => {
          const td = row[column.field]?.toString().replace(/(<([^>]+)>)/gi, "");

          return props.searchCaseSensitive
            ? td?.toString().includes(search.value)
            : td?.toString().toLowerCase().includes(search.value);
        })
        .some((value) => value === true)
    );
  }

  if (props.selectable && props.multi) {
    updateAllSelectedCheckbox();
  }
};
watch(
  () => props.search,
  (searchString) => {
    if (searchString !== undefined) {
      search.value = !props.searchCaseSensitive
        ? searchString.toLowerCase()
        : searchString;
    }

    if (searchString === "") {
      data.value.rows = defaultData.value.rows;
    } else {
      filter();
    }
    sort();
    pageKey.value = 0;
  }
);
watch(
  () => props.searchColumns,
  (searchCols: string[]) => {
    searchColumns.value = searchCols;
    filter();
    sort();
    pageKey.value = 0;
  }
);

// Select
const selectedRows = computed(() =>
  (defaultData.value.rows as DatatableItem[]).filter(
    (row) => row.selected === true
  )
);
const selectedIndexes = computed(() =>
  selectedRows.value.map((row) => row.mdbIndex)
);
const allRowsSelected = computed(
  () =>
    defaultData.value.rows &&
    selectedIndexes.value.length === defaultData.value.rows.length
);
const allFilteredRowsSelected = computed(() => {
  if (data.value.rows?.length === 0) {
    return false;
  }

  let allFilteredRowsSelected = allFilteredRowsSelectedCheckbox.value;

  if (
    data.value.rows &&
    selectedIndexes.value.length >= data.value.rows.length
  ) {
    allFilteredRowsSelected = true;
  }

  data.value.rows?.forEach((row) => {
    if (
      row.selected === false &&
      !selectedIndexes.value.includes(row.mdbIndex)
    ) {
      allFilteredRowsSelected = false;
    }
  });

  return allFilteredRowsSelected;
});
const allFilteredRowsSelectedCheckbox = ref(false);
const handleCheckboxChange = (rowId: number, rowChecked: boolean) => {
  if (!props.multi && rowChecked === false) {
    defaultData.value.rows?.forEach((row) => {
      if (row.mdbIndex !== rowId) {
        row.selected = false;
      }
    });
    data.value.rows?.forEach((row) => {
      if (row.mdbIndex !== rowId) {
        row.selected = false;
      }
    });
  }

  emitSelectedValues();
  updateAllSelectedCheckbox();
};
const toggleSelectAll = () => {
  if (!data.value.rows) {
    return;
  }

  if (allFilteredRowsSelected.value) {
    data.value.rows.forEach((row) => {
      row.selected = false;
      if (defaultData.value.rows) {
        defaultData.value.rows[row.mdbIndex].selected = false;
      }
    });
  } else {
    data.value.rows.forEach((row) => (row.selected = true));
  }

  emitSelectedValues();
};
const updateAllSelectedCheckbox = () => {
  nextTick(
    () =>
      (allFilteredRowsSelectedCheckbox.value = allFilteredRowsSelected.value)
  );
};
const emitSelectedValues = () => {
  nextTick(() => {
    emit("selected-rows", selectedRows.value);
    emit("selected-indexes", selectedIndexes.value);
    emit("all-selected", allRowsSelected.value);
    emit("all-filtered-rows-selected", allFilteredRowsSelected.value);
  });
};

// Events
const handleRowClick = (index: number) => {
  emit("row-click", index);
};
const handleCellBlur = (event: Event, rowIndex: number, field: string) => {
  if (props.edit && data.value.rows) {
    const target = event.target as HTMLElement;
    data.value.rows[rowIndex][field] = target.innerHTML;
    nextTick(() => {
      sort();
      emit("update", data.value);
    });
  }
};

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

defineExpose({ setActivePage });
</script>
